import { Box } from "@mui/material";
import React, { memo } from "react";
import Carousel from "react-material-ui-carousel";
import BannerReferral from "./BannerReferral";
import { Icons } from "assets";
import BannerSuggest from "./BannerSuggest";
import { useAuth } from "contexts";

const SlideBanner = () => {
	const { isLogged } = useAuth();
	return (
		<Box padding={"20px 30px"} width={"100%"}>
			<Carousel
				sx={{
					overflow: "unset",
				}}
				navButtonsWrapperProps={{
					className: "left-button",
				}}
				NextIcon={
					<Box
						width={"32px"}
						height={"32px"}
						display={"flex"}
						alignItems={"center"}
						sx={{
							backgroundColor: "rgb(255 255 255 / 40%)",
							backdropFilter: "blur(20px)",
						}}
						justifyContent={"center"}
						borderRadius={"50%"}>
						<Icons.NextSlide />
					</Box>
				}
				PrevIcon={
					<Box
						width={"32px"}
						height={"32px"}
						display={"flex"}
						alignItems={"center"}
						sx={{
							backgroundColor: "rgb(255 255 255 / 40%)",
							backdropFilter: "blur(20px)",
						}}
						justifyContent={"center"}
						borderRadius={"50%"}>
						<Icons.PrevSlide />
					</Box>
				}
				navButtonsAlwaysVisible={!isLogged}
				indicators={!isLogged}
				indicatorIconButtonProps={{
					style: {
						overflow: "hidden",
						backgroundColor: "#b9b8fd",
						width: "10px",
						height: "10px",
						borderRadius: "50px",
						fill: "#b9b8fd",
						color: "#b9b8fd",
						transition: "all 0.25s ease-in-out",
						margin: "2px",
					},
				}}
				activeIndicatorIconButtonProps={{
					style: {
						overflow: "hidden",
						backgroundColor: "#ffffff",
						width: "40px",
						height: "10px",
						borderRadius: "12px",
						fill: "#ffffff",
						color: "#ffffff",
						transition: "all 0.25s ease-in-out",
						margin: "2px",
					},
				}}
				indicatorContainerProps={{
					style: {
						textAlign: "center",
						position: "absolute",
						bottom: 15,
						zIndex: 9,
					},
				}}>
				{isLogged ? <BannerSuggest /> : <BannerReferral />}
				<BannerSuggest />
			</Carousel>
		</Box>
	);
};

export default memo(SlideBanner);
