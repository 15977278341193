/** @format */

export const PUBLIC_URL = {
	HOME: "/",
	LOGIN: "/login",
	SIGNUP: "/signup",
	FORGOT_PASSWORD: "/forgot-password",
	ACCESS_STORAGE: "/access_storage",
};

export const APP_ROOT = {
	DASHBOARD: "/",
	MODELS: "/models",
	STYLE_FIT: "/style-fit",
	STYLE_HAIR: "/style-hair",
	GEN_ME: "/gen-me",
	YOUR_GENERATION: "/your-generation",
	CHAT: "/chat",
	PLAN: "/pricing-plan",
	INFO_PAYMENT: "/info-payment",
	TRANSACTION: "/transactions",
	HISTORY: "/history-style-fit",
	HISTORY_STYLE_HAIR: "/history-style-hair",
	BODY_SHAPES: "/body-shapes",
	HISTORY_GEN_ME: "/history-gen-me",
	ANIMATE: "/animate",
	HISTORY_ANIMATE: "/history-animate",
	REGISTER: "/register",
	MAINTAIN: "/maintain",
};
