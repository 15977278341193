import { useMutation, useQuery } from "@tanstack/react-query";
import { userService } from "services/userService";
import { QueryKeys } from "utils";
import { useEffect } from "react";
import { useUser } from "./useUser";
import { UpdateProfileForm } from "types";
import SnackbarUtils from "utils/SnackbarUtils";

export const useProfile = () => {
	const { setUser, isLogged } = useUser();

	const { data, refetch } = useQuery({
		queryKey: [QueryKeys.PROFILE],
		queryFn: () => userService.getProfile(),
		enabled: isLogged,
	});

	const { mutate, isPending } = useMutation({
		mutationFn: (form: UpdateProfileForm) => userService.updateProfile(form),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Update success");
				const rs = await userService.getProfile();
				if (rs?.data?.data) {
					setUser(rs.data?.data);
				}
				return;
			}
			SnackbarUtils.warning(data?.message || "Update failed");
		},
		onError: () => {
			SnackbarUtils.warning("Update failed!");
		},
	});

	useEffect(() => {
		if (data) {
			setUser(data?.data?.data);
		}
	}, [JSON.stringify(data?.data)]);

	return {
		getProfile: refetch,
		updateProfile: mutate,
		isPending,
	};
};
