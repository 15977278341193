import React from "react";
import { Box, ImageListItem } from "@mui/material";
import { useDrag } from "react-dnd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IModelType } from "types/outfit";
import { mainColor } from "theme";
import { useLoadImage } from "hooks";

interface IImageDrag {
	handleClick: (item: IModelType) => void;
	item: IModelType;
	selectedImage: string | undefined;
	dropType: string;
}

const ImageDrag = ({
	handleClick,
	item,
	selectedImage,
	dropType,
}: IImageDrag) => {
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: dropType,
			item: item,
			end: (item, monitor) => {
				const dropResult = monitor.getDropResult<IImageDrag>();
				if (item && dropResult) {
					handleClick(item);
				}
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[],
	);

	const { error, loading, handleError, handleLoad, img404, imgLoad } =
		useLoadImage();

	return (
		<>
			<ImageListItem
				sx={{
					position: "relative",
					cursor: "pointer",
					transition: "all 0.25s ease-in-out",
					height: "auto",
					background:
						selectedImage === item?.id ? mainColor.bgLinear : "transparent",
					padding: "3px",
					borderRadius: "10px",
				}}>
				<Box
					borderRadius={"10px"}
					display={"flex"}
					overflow={"hidden"}
					position={"relative"}
					height={"100%"}
					justifyContent={"center"}>
					<Box
						ref={drag}
						sx={{ aspectRatio: 3 / 4, opacity: isDragging ? 0.5 : 1 }}
						display={"flex"}
						border={`${isDragging ? "1px" : "0px"} dashed #000`}
						bgcolor={"white"}
						justifyContent={"center"}>
						<LazyLoadImage
							style={{
								borderRadius: "10px",
								width: "100%",
								height: "100%",
								transition: "all 0.25s ease-in-out",
								display: "block",
								objectFit: "cover",
							}}
							onClick={() => handleClick(item)}
							srcSet={`${item?.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
							loading="lazy"
							effect="blur"
							src={
								error
									? img404
									: loading
									? imgLoad
									: `${item?.image}?w=164&h=164&fit=crop&auto=format`
							}
							onLoad={handleLoad}
							onError={handleError}
							alt={error ? "Error" : item?.name}
						/>
					</Box>
					<Box
						position={"absolute"}
						top={0}
						width={"100%"}
						height={"100%"}
						sx={{
							background: "rgba(0, 0, 0, 0.2)",
							left:
								selectedImage === item?.id ? 0 : { xs: "-90px", md: "-130px" },
							transition: "all 0.25s ease-in-out",
							borderRadius: "10px",
						}}
					/>
				</Box>
			</ImageListItem>
		</>
	);
};

export default ImageDrag;
