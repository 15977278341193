import React, { useCallback, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { mainColor } from "theme";
import { Icons } from "assets";
import { IDetailGallery, IGallery } from "types/outfit";
import { useNavigate } from "react-router-dom";

interface IVideoPose {
	handleClick?: (item: IGallery) => void;
	item: IDetailGallery;
	selectedImage: string | undefined;
	actionDelete?: (id: string) => void;
	isLoading?: boolean;
	location?: string;
}

const VideoPose = ({
	item,
	selectedImage,
	actionDelete,
	isLoading: isLoadingDelete,
	location,
}: IVideoPose) => {
	const refVideo = useRef<HTMLVideoElement>();
	const [isPlaying, setIsPlaying] = useState(false);
	const navigate = useNavigate();
	const handleMouseEnter = () => {
		if (refVideo.current && !isPlaying) {
			setIsPlaying(true);
			refVideo.current.play().catch((error) => {
				console.error("Error playing video:", error);
			});
		}
	};
	const handleMouseLeave = () => {
		if (refVideo.current && isPlaying) {
			setIsPlaying(false);
			refVideo.current.pause();
		}
	};

	const [isLoading, setIsLoading] = useState(true);
	const handleLoadStart = useCallback(() => {
		setIsLoading(true);
	}, []);
	const handleCanPlay = useCallback(() => {
		setIsLoading(false);
	}, []);
	const handlePlaying = useCallback(() => {
		setIsLoading(false);
	}, []);
	const handleWaiting = useCallback(() => {
		setIsLoading(true);
	}, []);

	return (
		<Box
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={() => navigate(`${location}?id=${item.id}`)}
			display={"flex"}
			flexDirection={"column"}
			sx={{
				cursor: "pointer",
				aspectRatio: "151 / 201",
				transition: "all 0.25s ease-in-out",
				borderRadius: "12px",
				":hover": {
					".item_video_pose": {
						opacity: 1,
					},
				},
			}}
			width={"calc((100% - 10px) / 2)"}
			gap={"10px"}
			alignItems={"center"}>
			<Box
				position={"relative"}
				height={"100%"}
				width={"100%"}
				borderRadius={"12px"}
				sx={{
					background:
						selectedImage === item?.id ? mainColor.bgLinear : "transparent",
					padding: "3px",
				}}
				overflow={"hidden"}>
				<Box
					ref={refVideo}
					borderRadius={"12px"}
					sx={{ objectFit: "cover" }}
					width={"100%"}
					height={"100%"}
					component={"video"}
					autoPlay={false}
					controls={false}
					onLoadStart={handleLoadStart}
					onCanPlay={handleCanPlay}
					onPlaying={handlePlaying}
					onWaiting={handleWaiting}
					loop
					muted>
					<source src={item.video} type="video/mp4" />
				</Box>
				{isLoading && (
					<Box
						bgcolor={"rgba(0, 0, 0, 0.7)"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						position={"absolute"}
						top={"50%"}
						left={"50%"}
						sx={{ transform: "translate(-50%,-50%)" }}
						width={"100%"}
						height={"100%"}>
						<CircularProgress size={25} />
					</Box>
				)}
				<Box
					position={"absolute"}
					bottom={0}
					className="item_video_pose"
					left={0}
					width={"100%"}
					height={"40px"}
					component={"div"}
					display={"flex"}
					zIndex={9}
					alignItems={"center"}
					justifyContent={"flex-end"}
					sx={{
						opacity: 0,
						transition: "all 0.25s ease-in-out",
						background:
							"linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))",
					}}>
					<Box
						onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
							event.stopPropagation();
							actionDelete?.(item?.id + "");
						}}
						component={"button"}
						sx={{ cursor: "pointer", outline: "none", border: "none" }}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						position={"absolute"}
						bgcolor={"#FFFFFF"}
						borderRadius={"10px"}
						padding={"10px 8px"}
						bottom={"10px"}
						right={"10px"}>
						{Icons.Delete()}
						{isLoadingDelete && (
							<Box
								position={"absolute"}
								width={"100%"}
								height={"100%"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								zIndex={2}>
								<CircularProgress
									sx={{
										height: "30px !important",
										width: "30px !important",
									}}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default VideoPose;
