import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { userService } from "services";
import { StatusRequest } from "types";
import { IDataTracking } from "types/outfit";
import { QueryKeys } from "utils";

export const useTrackingProcess = (idGallery: string) => {
	const [isTracking, setIsTracking] = useState(false);

	const { data, error, isLoading } = useQuery({
		queryKey: [QueryKeys.TRACKING_PROCESS, idGallery],
		refetchInterval: isTracking ? 20000 : false,
		refetchIntervalInBackground: true,
		enabled: isTracking && typeof idGallery === "string",
		queryFn: () => userService.trackingProcess(idGallery),
	});
	const dataTracking: IDataTracking = data?.data?.data;
	useEffect(() => {
		if (
			dataTracking?.status === StatusRequest.SUCCESS ||
			dataTracking?.status === StatusRequest.FAILURE
		) {
			setIsTracking(false);
		}
	}, [dataTracking?.status]);

	return {
		isTracking,
		dataTracking,
		error,
		isLoading,
		setIsTracking,
	};
};
