import React, { memo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icons, imgAnimateSub } from "assets";
import { TextCus } from "components";
import { fontSize, mainColor } from "theme";
import { useNavigate } from "react-router-dom";
import { useLayout, useUser } from "hooks";
import { EModelAuth } from "types";
import { APP_ROOT } from "utils/routes";

type TInfoFeature = {
	title: string;
	desc: string;
	link: string;
	img: string;
	image: string;
};

const BoxStart = ({ item }: { item: TInfoFeature }) => {
	const navigate = useNavigate();
	const { setOpenModalAuth } = useLayout();
	const { isLogged } = useUser();

	const refVideo = useRef<HTMLVideoElement>();
	const [isPlaying, setIsPlaying] = useState(false);
	const handleMouseEnter = () => {
		if (refVideo.current && !isPlaying) {
			setIsPlaying(true);
			refVideo.current.play().catch((error) => {
				console.error("Error playing video:", error);
			});
		}
	};
	const handleMouseLeave = () => {
		if (refVideo.current && isPlaying) {
			setIsPlaying(false);
			refVideo.current.pause();
		}
	};

	return (
		<Box
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			borderRadius={"12px"}
			onClick={() => {
				if (item.link === APP_ROOT.STYLE_HAIR) return;
				if (!isLogged) {
					return setOpenModalAuth(EModelAuth.LOGIN);
				}
				return navigate(item.link);
			}}
			className="transition_cus"
			overflow={"hidden"}
			sx={{
				transition: "all 0.25s ease-in-out",
				position: "relative",
				width: "calc((100% - 30px) / 4)",
				cursor: "pointer",
				":hover": {
					boxShadow: "2px 2px 19.2px 0px #9747FF80",
					".img_overload": {
						clipPath: "inset(0%)",
					},
					".imgVideo": {
						opacity: "0 !important",
					},
					".btn_function": {
						opacity: 1,
					},
					".divider_img": {
						left: "100%",
					},
					"::after": {
						transition: "all 0.35s easy-in-out",
						content: `${
							item.link === APP_ROOT.STYLE_HAIR ? '"Coming soon"' : '""'
						}`,
						width: "100%",
						height: "100%",
						bgcolor: "#ffffff75",
						position: "absolute",
						top: "0px",
						left: "0px",
						color: "white",
						fontSize: "26px",
						fontWeight: "600",
						display: item.link === APP_ROOT.STYLE_HAIR ? "flex" : "none",
						alignItems: "center",
						justifyContent: "center",
					},
				},
			}}>
			<Box
				borderRadius={"10px"}
				overflow={"hidden"}
				sx={{
					transition: "all 0.25s ease-in-out",
				}}
				position={"relative"}>
				<Box
					position={"relative"}
					borderRadius={"12px"}
					sx={{ aspectRatio: "294 / 216" }}
					overflow={"hidden"}>
					{item.title !== "ANIMATE ANYONE" ? (
						<LazyLoadImage
							style={{
								position: "absolute",
								display: "block",
								objectFit: "cover",
								width: "100%",
								transition: "all 0.35s ease-in-out",
								height: "auto",
								borderRadius: "12px",
							}}
							src={item.image}
							alt="banner"
						/>
					) : (
						<>
							<Box
								ref={refVideo}
								borderRadius={"12px"}
								position={"relative"}
								sx={{ objectFit: "cover" }}
								width={"100.6%"}
								height={"175.5%"}
								component={"video"}
								autoPlay={true}
								controls={false}
								loop
								muted>
								<source src={item.img} type="video/mp4" />
							</Box>
							<LazyLoadImage
								className="imgVideo"
								style={{
									zIndex: 1,
									position: "absolute",
									opacity: 1,
									left: 0,
									top: 0,
									objectFit: "cover",
									width: "100%",
									height: "100%",
									borderRadius: "12px",
								}}
								src={item.image}
								alt="banner"
							/>
							<LazyLoadImage
								style={{
									zIndex: 1,
									position: "absolute",
									opacity: 1,
									left: "2%",
									bottom: 0,
								}}
								src={imgAnimateSub}
								alt="banner"
							/>
						</>
					)}
					{item.title !== "ANIMATE ANYONE" && (
						<Box
							width={"100%"}
							height={"100%"}
							component={"figure"}
							className="img_overload"
							sx={{
								clipPath: "inset(0% 100% 0% 0%)",
								inset: "0px",
								position: "absolute",
								transition: "clip-path 0.35s ease-in-out 0s",
								willChange: "clip-path",
								margin: 0,
							}}>
							<LazyLoadImage
								style={{
									opacity: 1,
									position: "absolute",
									display: "block",
									objectFit: "cover",
									width: "100%",
									height: "100%",
									borderRadius: "12px",
								}}
								src={item.img}
								alt="banner"
							/>
						</Box>
					)}

					{item.title !== "ANIMATE ANYONE" && (
						<Box
							sx={{
								transition: "all 0.35s ease-in-out",
							}}
							className="divider_img"
							position={"absolute"}
							left={"-20px"}>
							{Icons.DividerImg()}
						</Box>
					)}
				</Box>
				<Box
					bgcolor={"rgba(255, 255, 255, 0.7)"}
					padding={"15px"}
					borderRadius={" 0 0 10px 10px"}
					width={"100%"}
					height={"fit-content"}
					display={"flex"}
					justifyContent={"space-between"}>
					<Box
						display={"flex"}
						justifyContent={"start"}
						flexDirection={"column"}>
						<TextCus
							fontWeight={"600"}
							fontSize={fontSize.xs}
							color={mainColor.secondary}>
							{item.title}
						</TextCus>
						<TextCus
							fontWeight={"500"}
							sx={{
								fontSize: {
									xs: "11px",
									lg: "12px",
								},
							}}
							color={"#666666"}>
							{item.desc}
						</TextCus>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default memo(BoxStart, (prev, next) => {
	return prev.item.title === next.item.title;
});
