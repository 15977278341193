/** @format */
import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TabCus, TextCus } from "components";
import { fontSize, mainColor } from "theme";
import BoxUpload from "./BoxUpload";
import { PresetModel } from "./PresetModel";
import { arrTab, arrTabStepTwoHair } from "utils";
import { Gallery } from "./Gallery";
import { useStyleHair, useStyleHairStore } from "hooks";
import { PresetHair } from "./PresetHair";
import { YourModel } from "./YourModel";
import { YourHair } from "./YourHair";
import HairColor from "./HairColor";

const Sidebar = () => {
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		currentStep,
		setSelectedFileHair,
		setImageHair,
		colorHair,
		setColorHair,
	} = useStyleHairStore();
	const [itemTab, setItemTab] = useState("UPLOAD");

	const { useGetHairColor } = useStyleHair();
	const { categories } = useGetHairColor({
		page: 1,
		limit: 24,
		type: "HAIR_COLOR",
	});

	const renderSidebar = useCallback(() => {
		switch (currentStep) {
			case 0:
				return (
					<Box flex={1}>
						<Box height={"50px"}>
							<TabCus arrTab={arrTab} setValueTab={(v) => setItemTab(v)} />
						</Box>
						<Box sx={{ height: "calc(100vh - 218px)" }}>
							{itemTab === "UPLOAD" ? (
								<Box padding={"15px"} height={"100%"}>
									<BoxUpload
										onFileSelect={(file: File) => {
											setSelectedFileGallery(null);
											setSelectedFileModel(file);
											setModelImage(URL.createObjectURL(file));
										}}
										onClear={() => setModelImage("")}
									/>
								</Box>
							) : (
								<Box
									display={"flex"}
									gap={"20px"}
									padding={"15px"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<Gallery />
									<PresetModel />
									<YourModel />
								</Box>
							)}
						</Box>
					</Box>
				);
			default:
				return (
					<Box flex={1}>
						<Box height={"50px"}>
							<TabCus
								arrTab={arrTabStepTwoHair}
								setValueTab={(v) => setItemTab(v)}
							/>
						</Box>

						<Box sx={{ height: "calc(100vh - 230px)" }}>
							{itemTab === "UPLOAD" ? (
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									flexDirection={"column"}
									padding={"15px"}
									sx={{ overflowY: "auto" }}
									gap={"20px"}
									height={"100%"}>
									<HairColor
										colors={categories}
										colorHairChoose={colorHair}
										setColor={setColorHair}
									/>
									<BoxUpload
										onFileSelect={(file) => {
											setSelectedFileHair(file);
											setImageHair(URL.createObjectURL(file));
										}}
										onClear={() => setImageHair("")}
									/>
								</Box>
							) : (
								<Box
									display={"flex"}
									gap={"20px"}
									padding={"15px"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<HairColor
										colors={categories}
										colorHairChoose={colorHair}
										setColor={setColorHair}
									/>
									<PresetHair />
									<YourHair />
								</Box>
							)}
						</Box>
					</Box>
				);
		}
	}, [currentStep, itemTab, colorHair]);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"space-between"}
			position={"relative"}
			height={"100%"}>
			<Box
				display={"flex"}
				paddingX={"15px"}
				flexDirection={"column"}
				gap={"2px"}
				justifyContent={"center"}
				height={"98px"}>
				<TextCus
					fontSize={fontSize.sm}
					color={mainColor.secondary}
					fontWeight={"700"}>
					YOUR STATION
				</TextCus>
				<TextCus fontSize={"12px"} color={"#FF52F0"} fontWeight={"500"}>
					{currentStep === 0
						? `Upload your photos or choose one model in “Use Model” tab`
						: "Upload your photos or choose in “Hair Collection” tab"}
				</TextCus>
			</Box>
			{renderSidebar()}
		</Box>
	);
};

export default Sidebar;
