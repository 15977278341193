import { TParams, TResponse } from "types";
import axiosInstance from "./axios";
import { END_POINT } from "utils";
import { TVideoAnimate } from "types/animate";

export const animateService = {
	getVideoPreset: async (
		params: TParams,
		endpoint = END_POINT.ANIMATE.GET_ANIMATE,
	): Promise<TResponse<TVideoAnimate[]>> => {
		const { page, limit } = params;
		const url = `${endpoint}?page=${page}&limit=${limit}`;
		const data = await axiosInstance.get(url);
		return data.data;
	},
};
