import { Box } from "@mui/material";
import { TextCus } from "components";
import React, { memo } from "react";
import { mainColor } from "theme";

const ButtonAction = ({
	icon,
	text,
	action,
}: {
	icon: React.ReactNode;
	text: string;
	action: () => void;
}) => {
	return (
		<Box
			sx={{
				cursor: "pointer",
				transition: "all 0.25s ease-in-out",
				":hover": {
					background: mainColor.bgButton,
				},
			}}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			flex={1}
			height={"44px"}
			gap={"6px"}
			borderRadius={"12px"}
			component={"div"}
			onClick={action}
			bgcolor={mainColor.white}>
			{icon}
			<TextCus fontSize={"12px"} fontWeight={"400"}>
				{text}
			</TextCus>
		</Box>
	);
};

export default memo(ButtonAction, (prev, next) => prev.icon === next.icon);
