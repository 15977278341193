/** @format */

export const END_POINT = {
	AUTH: {
		LOGIN: "/auth/login",
		REGISTER: "/auth/register",
		REFRESH_TOKEN: "/auth/refresh-token",
		CHANGE_PASSWORD: "/auth/change-password",
		LOGIN_SOCIAL: "/auth/login-social",
		VERIFY_OTP: "/auth/verify-otp",
		VERIFY_EMAIL: "/auth/verify-email",
	},
	USER: {
		GET_PROFILE: "/user/me",
		LOGOUT: "/user/logout",
		UPDATE_PROFILE: "/user/profile",
		UPDATE_PASS: "/auth/update-password",
		GET_BLOB_IMG: "storage/download-link",
		CONFIRM_TERM: "/user/is-agree",
		ACTIVE_REFERRAL: "/user/active-reffral",
		NOTIFICATION: "/notifications",
	},
	OTP: {
		SEND_OTP: "/send-mail/otp",
	},
	MODEL: {
		GET_EXAMPLE: "/model-body/example",
		GET_RANDOM: "/model-body/random",
		GET_YOUR_MODEL: "/model-body/your-model",
		CREATE_YOUR_MODEL: "/model-body/create-your-model",
		DELETE_YOUR_MODEL: "/model-body/user/delete",
		CREATE_BODY_SHAPE: "/model-body/user/body-shape",
		PRESET_MODEL: "/model-body/preset-model",
	},
	CLOTHES: {
		GET_EXAMPLE: "/clothes/example",
		YOUR_ITEMS: "/clothes/your-clothes",
	},
	AI_TOOLS: {
		OUTFIT_TOOLS: "/ai-tools/outfit",
		FACE_SWAP: "/ai-tools/gen-me",
		STYLE_HAIR_TOOLS: "/ai-tools/hair-style",
		ANIMATE: "/ai-tools/animation-anyone",
	},
	GALLERY: {
		GET_GALLERY: "/gallery/user",
		DELETE_GALLERY: "/gallery/delete-your-gallery",
		DETAIL_GALLERY: "/gallery/details?id=",
		RENAME_GALLERY: "/gallery/rename-gallery",
		TRACKING_GALLERY: "/gallery/tracking-ai",
	},
	PAYMENT: {
		ORDER: "/payments/paypal/orders",
		APPROVE: "/payments/paypal/approve",
		PAYMENT_PAYPAL: "/payments",
		PLAN: "/plan",
		DETAIL_PLAN: "/plan/detail?id=",
		TRANSACTIONS: "/transaction-plans/user",
		CONTACT_US: "/contact-us",
	},
	HAIR: {
		CATEGORIES: "/categories",
		GET_YOUR_HAIR: "/hair-style/your-hair-style",
		DELETE_YOUR_HAIR: "/hair-style/user/delete",
		CREATE_YOUR_HAIR: "/hair-style/create-your-hair-style",
	},
	GEN_ME: {
		GET_YOUR_GEN_ME: "/gen-me/your-gen-me",
		DELETE_YOUR_GEN_ME: "/gen-me/user/delete",
		CREATE_YOUR_GEN_ME: "/gen-me/create-your-gen-me",
	},
	ANIMATE: { GET_ANIMATE: "/animation-anyone" },
};
