import { ModalEventSuccess } from "components/ModalGlobal";
import { useSocket } from "contexts";
import { useProfile, useUser } from "hooks";
import React, { useEffect, useState } from "react";

const BoxEvent = () => {
	const { socket } = useSocket();
	const { user } = useUser();
	const { getProfile } = useProfile();
	const [openSuccess, setOpenSuccess] = useState(false);

	useEffect(() => {
		if (socket) {
			socket.on(`eventActiveCode-${user?.id}`, (data) => {
				console.log("EventActiveCode ==============>", data);
				setOpenSuccess(true);
				getProfile();
			});
			return () => {
				socket.off(`eventActiveCode-${user?.id}`);
			};
		}
	}, [socket, user]);

	return (
		<>
			{openSuccess && (
				<ModalEventSuccess
					open={openSuccess}
					onClose={() => setOpenSuccess(false)}
				/>
			)}
		</>
	);
};

export default BoxEvent;
