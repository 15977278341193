import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLoadImage, useModel, useOutfitStore, useUser } from "hooks";
import { TParams } from "types";
import { useNavigate } from "react-router-dom";
import { IModelType } from "types/outfit";
import { APP_ROOT } from "utils/routes";

const PresetModels = () => {
	const navigate = useNavigate();
	const { isLogged } = useUser();
	const { useGetModelRandom } = useModel();
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 56,
	});

	const { models: dataModel } = useGetModelRandom(paramsModels);
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();

	const handleChooseModel = useCallback((item: IModelType) => {
		setSelectedFileModel(item._id as string);
		setModelImage(item.image as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
	}, []);

	const { error, loading, handleError, handleLoad, img404, imgLoad } =
		useLoadImage();

	return (
		<Box maxWidth={"1156px"} margin={"0 auto"} mt={isLogged ? "70px" : "150px"}>
			<Box
				mt={"20px"}
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				gap={"20px"}
				flexWrap={"wrap"}>
				{dataModel.map((item, index) => {
					return (
						<Box
							width={"133px"}
							component={"div"}
							onClick={() => handleChooseModel(item)}
							height={"133px"}
							borderRadius={"100%"}
							border="2px solid #ccc"
							overflow={"hidden"}
							sx={{ cursor: "pointer" }}
							key={`${item?.id || "" + index}`}>
							<LazyLoadImage
								style={{
									display: "block",
									objectFit: "contain",
									width: "100%",
								}}
								srcSet={`${item?.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								src={
									error
										? img404
										: loading
										? imgLoad
										: `${item?.image}?w=164&h=164&fit=crop&auto=format`
								}
								onLoad={handleLoad}
								onError={handleError}
								alt={error ? "Error" : "model"}
							/>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default PresetModels;
