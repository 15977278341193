/** @format */

import "assets/style/index.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import App from "App";
import { ModalGlobal } from "components";
import { AuthProvider, SocketProvider, UserProvider } from "contexts";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import theme from "theme/themes";
import { I18nextProvider } from "react-i18next";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { initialOptionsPaypal, SnackbarUtilsConfigurator } from "utils";
import i18n from "i18n";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);
root.render(
	<QueryClientProvider client={queryClient}>
		<GoogleOAuthProvider clientId={`${process.env.REACT_APP_GG_CLIENT_ID}`}>
			<I18nextProvider i18n={i18n}>
				<PayPalScriptProvider options={initialOptionsPaypal}>
					<SocketProvider>
						<AuthProvider>
							<UserProvider>
								<ThemeProvider theme={theme}>
									<CssBaseline />
									<ToastContainer />
									<ModalGlobal />
									<SnackbarProvider
										maxSnack={3}
										anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
										<DndProvider backend={HTML5Backend}>
											<BrowserRouter>
												<App />
											</BrowserRouter>
										</DndProvider>
										<SnackbarUtilsConfigurator />
									</SnackbarProvider>
								</ThemeProvider>
							</UserProvider>
						</AuthProvider>
					</SocketProvider>
				</PayPalScriptProvider>
			</I18nextProvider>
		</GoogleOAuthProvider>
	</QueryClientProvider>,
);
