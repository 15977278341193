import { Box, CircularProgress } from "@mui/material";
import { TextCus } from "components/TextCus";
import {
	useAnimateStore,
	useGenMeStore,
	useOutfitStore,
	useStyleHairStore,
} from "hooks";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { mainColor } from "theme";
import { APP_ROOT } from "utils/routes";

const ProcessGlobal = () => {
	const navigate = useNavigate();
	const { isLoadingProcess } = useOutfitStore();
	const { isLoadingProcess: isLoadingProcessStyleHair } = useStyleHairStore();
	const { isLoadingProcess: isLoadingProcessGenMe } = useGenMeStore();
	const { isLoadingProcess: isLoadingProcessAnimate } = useAnimateStore();
	const location = useLocation();
	if (
		(isLoadingProcess && location.pathname === APP_ROOT.STYLE_FIT) ||
		(isLoadingProcessStyleHair && location.pathname === APP_ROOT.STYLE_HAIR) ||
		(isLoadingProcessGenMe && location.pathname === APP_ROOT.GEN_ME) ||
		(isLoadingProcessAnimate && location.pathname === APP_ROOT.ANIMATE)
	)
		return <></>;
	if (
		isLoadingProcess ||
		isLoadingProcessStyleHair ||
		isLoadingProcessGenMe ||
		isLoadingProcessAnimate
	) {
		return (
			<Box
				component={"button"}
				border={"none"}
				onClick={() =>
					navigate(
						isLoadingProcess
							? APP_ROOT.STYLE_FIT
							: isLoadingProcessStyleHair
							? APP_ROOT.STYLE_HAIR
							: APP_ROOT.GEN_ME,
					)
				}
				position={"absolute"}
				zIndex={999}
				sx={{ background: mainColor.bgLinear, cursor: "pointer" }}
				top={"80px"}
				display={"flex"}
				gap={"10px"}
				alignItems={"center"}
				padding={"10px"}
				borderRadius={"12px"}
				right={"15px"}>
				<TextCus color={"white"} fontWeight={"700"}>
					You have a running process
				</TextCus>
				<CircularProgress
					sx={{
						color: "white",
						width: "20px !important",
						height: "20px !important",
					}}
				/>
			</Box>
		);
	} else {
		return <></>;
	}
};

export default ProcessGlobal;
