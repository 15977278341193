import { Box, CircularProgress } from "@mui/material";
import { Icons } from "assets";
import React from "react";

const ButtonDelete = ({
	isLoading,
	actionDelete,
}: {
	isLoading: boolean;
	actionDelete: () => void;
}) => {
	return (
		<Box
			onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation();
				actionDelete?.();
			}}
			component={"button"}
			sx={{ cursor: "pointer", outline: "none", border: "none" }}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			position={"absolute"}
			padding={"7px"}
			bgcolor={"#FFFFFF"}
			borderRadius={"10px"}
			bottom={"7px"}
			right={"7px"}>
			{Icons.Delete()}
			{isLoading && (
				<Box
					position={"absolute"}
					width={"100%"}
					height={"100%"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					zIndex={2}>
					<CircularProgress
						sx={{
							height: "25px !important",
							width: "25px !important",
						}}
					/>
				</Box>
			)}
		</Box>
	);
};

export default ButtonDelete;
