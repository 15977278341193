import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useClothes, useOutfitStore } from "hooks";
import { TParams } from "types";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { TextCus } from "components";
import { Icons } from "assets";

export const YourItems: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileClothes,
		setImageClothes,
		selectedFileClothes,
		valueCategory,
		setCurrentStep,
	} = useOutfitStore();
	const { useGetYourClothes } = useClothes();

	// ======================== STATE ========================
	const [paramsOutfit, setParamsOutfit] = useState<TParams>({
		page: 1,
		limit: 3,
		typeOutfit: valueCategory,
	});
	const {
		clothes: dataOutfit,
		isLoading,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useGetYourClothes(paramsOutfit);

	useEffect(() => {
		setParamsOutfit({
			...paramsOutfit,
			typeOutfit: valueCategory,
		});
	}, [valueCategory]);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Your Items" />
			<ListImage
				isLoading={isLoading}
				images={dataOutfit}
				selectedImage={selectedFileClothes as string}
				setSelectedImage={(it) => {
					setSelectedFileClothes(it.id as string);
					setImageClothes(it.image as string);
					setCurrentStep(2);
				}}
				dropType={EDropType.OUTFIT}
			/>
			{hasNextPage && (
				<Box
					component={"div"}
					onClick={() => fetchNextPage()}
					width={"fit-content"}
					sx={{ cursor: "pointer" }}
					margin={"0 auto"}
					padding={"4px 10px"}
					borderRadius={"12px"}
					bgcolor={"rgba(149, 119, 217, 0.2)"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"15px"}>
					<TextCus>See more</TextCus>
					{isFetchingNextPage ? (
						<CircularProgress size={15} />
					) : (
						<Icons.ArrowSelect />
					)}
				</Box>
			)}
		</Box>
	);
};
