import { Box } from "@mui/material";
import { styleGenLoading } from "assets";
import React from "react";
import { LoadingBar, TextCus } from "components";
import { mainColor } from "theme";

const BoxLoading = ({
	isStart,
	valueProcess,
	isLoadingHistory,
}: {
	isStart?: boolean;
	valueProcess: number;
	isLoadingHistory?: boolean;
}) => {
	return (
		<>
			<Box>
				<img
					src={styleGenLoading}
					width={"50px"}
					height={"50px"}
					alt="loading"
				/>
			</Box>
			{!isLoadingHistory && (
				<>
					{isStart ? (
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							flexDirection={"column"}>
							<TextCus
								fontWeight={"600"}
								fontSize={"14px"}
								color={mainColor.textSecondary}>
								Please wait
							</TextCus>
							<TextCus
								fontWeight={"500"}
								fontSize={"13px"}
								color={mainColor.textSecondary}>
								Your progress will start soon!
							</TextCus>
						</Box>
					) : (
						<TextCus
							fontWeight={"600"}
							fontSize={"14px"}
							color={mainColor.textSecondary}>
							Generating your image, completing in 1 minute
						</TextCus>
					)}
					<Box width={"200px"} maxWidth={"200px"}>
						<LoadingBar value={valueProcess} />
					</Box>
				</>
			)}
		</>
	);
};

export default BoxLoading;
