/** @format */

import { EModalBodyShape } from "types";
import { IDataBodyShape } from "types/outfit";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
	showModalCreate: EModalBodyShape;
	dataResult: IDataBodyShape;
	showModalSuggest: boolean;
};

type Actions = {
	setShowModelCreate: (v: EModalBodyShape) => void;
	setDataResult: (v: IDataBodyShape) => void;
	setShowModalSuggest: (v: boolean) => void;
};

const initialState: State = {
	showModalCreate: EModalBodyShape.DEFAULT,
	showModalSuggest: false,
	dataResult: {
		bmi: 0,
		bodyShapeId: "",
		createdAt: "",
		height: 0,
		id: "",
		image: "",
		name: "",
		typeModel: "",
		uid: "",
		weight: 0,
		_id: "",
	},
};

export const useStoreBodyShape = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			setShowModelCreate: (v) => set({ showModalCreate: v }),
			setDataResult: (v) => set({ dataResult: v }),
			setShowModalSuggest: (v) => set({ showModalSuggest: v }),
		}),
		{
			name: "bodyShapeStore",
		},
	),
);
