/** @format */
/**
 * @description the interface of user authentication entity
 * @export
 * @interface IAppState
 */

import { EnumValueDataType } from "utils";

export interface IInforForgot {
	email: string;
	confirmCode: string;
	verifyCode: string;
}
export interface IAppState {
	loading: boolean;
	language: string;
	error: string | null;
	captcha?: string;
	showCaptcha?: boolean;
	isAuth: boolean;
	inforForgot: IInforForgot;
	authToken?: string;
}
export interface INofifyState {
	message: string;
	type: "success" | "info" | "warning" | "error";
	options: {
		position: {
			vertical: "top" | "bottom";
			horizontal: "left" | "center" | "right";
		};
		autoHideDuration: number;
		useI18n: boolean;
	};
}

export type IValueType = keyof typeof EnumValueDataType;

export enum ELanguage {
	KO = "ko",
	EN = "en",
}
export interface IKeyAuth {
	authToken: string;
	refreshToken: string;
	userId?: string;
	updateParent?: boolean;
}

export type IThemeColor = "main" | "light" | "dark";
export type IThemeMode = "default";

export type TPagination = {
	limit: number;
	page: number;
	total: number;
	totalPage: number;
};

export type TResponse<T> = {
	data: T;
	pagination?: TPagination;
	statusCode: number;
	message: string;
};

export type TParams = {
	page: number;
	limit: number;
	typeModel?: string;
	typeOutfit?: string;
	[x: string]: string | number | undefined;
};

export enum TypeServiceName {
	GEN_ME = "GEN_ME",
	OUTFIT = "OUTFIT",
	HAIR_SWAP = "HAIR_SWAP",
	BODY_SHAPE = "BODY_SHAPE",
	ANIMATE = "ANIMATION_ANYONE",
}

export enum TypeYourBody {
	YOUR_BODY = "YOUR_BODY",
	BODY_SHAPE = "BODY_SHAPE",
}
export const arrTab = [
	{
		title: "Style Fit",
		value: TypeServiceName.OUTFIT,
	},
	{
		title: "Gen Me",
		value: TypeServiceName.GEN_ME,
	},
	{
		title: "Style Hair",
		value: TypeServiceName.HAIR_SWAP,
	},
	{
		title: "Animate Anyone",
		value: TypeServiceName.ANIMATE,
	},
];

export enum StatusRequest {
	NEW = "NEW",
	STARTED = "STARTED",
	PROCESSING = "PROCESSING",
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
}

export enum ENotification {
	MAINTAIN = "MAINTAIN",
	DEFAULT = "",
}
