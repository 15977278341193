import { Box } from "@mui/material";
import { bgBn2, leftBn2, no } from "assets";
import { TextCus } from "components";
import { useAuth } from "contexts";
import { useLayout, useStoreBodyShape } from "hooks";
import React, { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fontSize } from "theme";
import { EModalBodyShape, EModelAuth } from "types";

const arrContent = [
	"Customize your unique measurements",
	"Design your AI version fast and accurate",
	"Use your body shape with different features in StyleGen",
];

const BannerSuggest = () => {
	const { setShowModelCreate } = useStoreBodyShape();
	const { isLogged } = useAuth();
	const { setOpenModalAuth } = useLayout();

	return (
		<Box
			bgcolor={"#A3A3A3"}
			borderRadius={"21px"}
			width={"100%"}
			padding={"30px"}
			sx={{
				overflowY: "auto",
				overflowX: "hidden",
				backgroundImage: `url(${bgBn2})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "264px",
			}}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				width={"90%"}
				height={"100%"}
				margin={"auto"}>
				<Box display={"flex"} flexDirection={"column"}>
					<Box
						display={"flex"}
						width={"100%"}
						alignItems={"center"}
						justifyContent={"space-between"}
						mb={"10px"}>
						<TextCus
							fontSize={fontSize.md}
							fontWeight={"700"}
							color={"#050358"}>
							CREATE YOUR BODY SHAPE
						</TextCus>
					</Box>
					<Box
						padding={"0 20px"}
						display={"flex"}
						flexDirection={"column"}
						gap={"10px"}>
						{arrContent.map((item, index) => {
							return (
								<Box
									key={index}
									display={"flex"}
									gap={"10px"}
									alignItems={"center"}>
									<img src={no} alt="no" />
									<TextCus
										fontSize={fontSize.xs}
										fontWeight={"400"}
										color={"#050358"}>
										{item}
									</TextCus>
								</Box>
							);
						})}
						<Box
							component={"div"}
							onClick={() => {
								if (!isLogged) return setOpenModalAuth(EModelAuth.LOGIN);
								setShowModelCreate(EModalBodyShape.CREATE);
							}}
							bgcolor={"#FFFFFF"}
							mt={"10px"}
							sx={{
								transition: "all 0.25s ease-in-out",
								cursor: "pointer",
								"&:hover": {
									opacity: 0.8,
								},
							}}
							width={"173px"}
							height={"41px"}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							borderRadius={"12px"}>
							<TextCus fontSize={"15px"} color={"#3B3899"} fontWeight={"600"}>
								Create now
							</TextCus>
						</Box>
					</Box>
				</Box>
				<LazyLoadImage src={leftBn2} alt="Left" />
			</Box>
		</Box>
	);
};

export default memo(BannerSuggest);
