/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { TextCus } from "components/TextCus";
import { ButtonCus } from "components/ButtonCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import { height, Icons, weight } from "assets";
import { SliderRangeCus } from "components/SliderCus";
import useClickOutside from "hooks/useClickOutside";
import { useMutation } from "@tanstack/react-query";
import { IBodyShapeParams } from "types/outfit";
import { modelService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";
import { useModel, useStoreBodyShape, useUser } from "hooks";
import { KEY_CONTEXT } from "utils";
import { useTranslation } from "react-i18next";

interface IModalCreateBodyShape {
	open: boolean;
	onClose: () => void;
	onOpenResult: () => void;
}

const arrGender = [
	{ title: "Male", value: "MALE", icon: (color) => Icons.Male(color) },
	{ title: "Female", value: "FEMALE", icon: (color) => Icons.FeMale(color) },
];

const feetToCm = (fit) => {
	const feetPerCm = 0.0328084;
	return Math.round(fit / feetPerCm);
};

const ModalCreateBodyShape = ({
	open,
	onClose,
	onOpenResult,
}: IModalCreateBodyShape) => {
	const { t } = useTranslation();
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));
	const [isOpen, setIsOpen] = useState(false);
	const [optionHeight, setOptionHeight] = useState<"cm" | "feet">("cm");
	const { setDataResult } = useStoreBodyShape();
	const { useGetYourModels } = useModel();
	const { refetch } = useGetYourModels({
		page: 1,
		limit: 6,
		type: "BODY_SHAPE",
	});

	const [valueHeight, setValueHeight] = useState(120);
	const [valueWeight, setValueWeight] = useState(45);
	const [valueGender, setValueGender] = useState<string>("MALE");

	const createYourBodyShapeMutation = useMutation({
		mutationFn: (data: IBodyShapeParams) =>
			modelService.createYourBodyShape(data),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				localStorage.setItem(KEY_CONTEXT.SUGGEST_BODY, "true");
				setDataResult(data?.data);
				refetch();
				onOpenResult();
				return;
			}
			SnackbarUtils.warning("Height > 120, weight > 45");
		},
		onError: () => {
			SnackbarUtils.warning("Create failed!");
		},
	});

	const createYourModelShape = useCallback(
		() =>
			createYourBodyShapeMutation.mutate({
				height: optionHeight === "feet" ? feetToCm(valueHeight) : valueHeight,
				weight: valueWeight,
				typeModel: valueGender,
				name: new Date() + "",
			}),
		[valueHeight, valueWeight, valueGender, optionHeight],
	);

	const { languages } = useUser();

	const onReset = useCallback(() => {
		setValueHeight(0);
		setValueWeight(0);
		setValueGender("MALE");
	}, []);

	useEffect(() => {
		if (optionHeight === "feet") {
			setValueHeight(3.94);
		} else {
			setValueHeight(120);
		}
	}, [optionHeight]);

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={mainColor.white}
					flexDirection={"column"}
					sx={{
						maxWidth: "680px",
						maxHeight: "450px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"764px"}
					overflow={"hidden"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						padding={"10px 20px"}
						bgcolor={"#E8E7FE"}
						justifyContent={"space-between"}>
						<TextCus fontWeight={"700"} fontSize={fontSize.sm}>
							Custom your body shape
						</TextCus>
						<IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
							<CloseOutlined />
						</IconButton>
					</Box>
					<Box
						padding={"20px"}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"space-between"}
						gap={"20px"}
						width={"100%"}
						flex={1}>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							gap={"5px"}
							width={"100%"}>
							<TextCus
								color={mainColor.textMain}
								fontSize={fontSize.xs}
								textAlign={"center"}
								fontWeight={"600"}>
								Calculate your Body Mass Index (BMI) and visualize your body
								shape
							</TextCus>
							<TextCus color={"#A3A3A3"} fontSize={"12px"} fontWeight={"400"}>
								BMI is calculated using your height and weight and is
								approximately related to body fat percentage.
							</TextCus>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"20px"}>
							{arrGender.map((item) => (
								<Box
									key={item.title}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}
									sx={{
										cursor: "pointer",
										transition: "all 0.25s ease-in-out",
									}}
									component={"div"}
									onClick={() => setValueGender(item.value)}
									height={"40px"}
									width={"116px"}
									borderRadius={"12px"}
									bgcolor={valueGender === item.value ? "#E8E7FE" : "#EEEEEE"}
									gap={"15px"}>
									{item.icon(
										valueGender === item.value ? "#3E227C" : "#666666",
									)}
									<TextCus
										color={valueGender === item.value ? "#3E227C" : "#666666"}
										fontWeight={"600"}
										fontSize={fontSize.xs}>
										{item.title}
									</TextCus>
								</Box>
							))}
						</Box>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							justifyContent={"center"}
							width={"100%"}
							borderRadius={"12px"}
							bgcolor={"#EEEEEE"}
							gap={"25px"}
							padding={"15px"}>
							<Box
								display={"flex"}
								alignItems={"center"}
								width={"100%"}
								gap={"20px"}
								borderRadius={"12px"}
								justifyContent={"space-between"}>
								<Box
									display={"flex"}
									gap={"10px"}
									alignItems={"center"}
									justifyContent={"center"}>
									<img src={height} alt="Height" />{" "}
									<TextCus
										width={languages.value === "vi" ? "75px" : "40px"}
										fontWeight={"600"}
										fontSize={fontSize.xs}
										color={mainColor.mainBlack}>
										Height
									</TextCus>
								</Box>
								<SliderRangeCus
									setVSlider={(v) => setValueHeight(v)}
									valueDefault={valueHeight}
									maxValue={optionHeight === "feet" ? 6.4 : 195}
									minValue={optionHeight === "feet" ? 3.94 : 120}
								/>
								<Box width={"154px"} height={"33px"} display={"flex"}>
									<Box
										width={"77px"}
										borderRadius={"12px 0 0 12px"}
										component={"input"}
										padding={"5px"}
										textAlign={"center"}
										max={195}
										min={0}
										fontWeight={"600"}
										type="number"
										value={valueHeight + ""}
										onChange={(e) =>
											+e.target.value <= 195 && setValueHeight(+e.target.value)
										}
										border={"1px solid #B2B2B2"}
									/>
									<Box
										width={"77px"}
										ref={dropdownRef}
										display={"flex"}
										alignItems={"center"}
										gap={"5px"}
										sx={{ cursor: "pointer" }}
										justifyContent={"center"}
										border={"1px solid #B2B2B2"}
										borderLeft={"none"}
										borderRadius={`0 12px ${isOpen ? "0px" : "12px"} 0`}
										bgcolor={"white"}
										color={mainColor.textMain}
										fontSize={fontSize.xs}
										onClick={() => setIsOpen(!isOpen)}
										position={"relative"}
										fontWeight={"400"}>
										{optionHeight}
										<Icons.ArrowDown />
										{isOpen && (
											<Box
												position={"absolute"}
												bgcolor={"white"}
												bottom={"-30px"}
												right={"-1px"}
												component={"div"}
												onClick={() =>
													setOptionHeight((pre) =>
														pre === "cm" ? "feet" : "cm",
													)
												}
												width={"78px"}
												height={"30px"}
												display={"flex"}
												alignItems={"center"}
												border={"1px solid #B2B2B2"}
												justifyContent={"center"}
												borderRadius={"0 0 12px 0"}
												color={mainColor.textMain}
												fontSize={fontSize.xs}
												fontWeight={"400"}>
												{optionHeight === "cm" ? "feet" : "cm"}
											</Box>
										)}
									</Box>
								</Box>
							</Box>
							<Box
								display={"flex"}
								alignItems={"center"}
								width={"100%"}
								gap={"20px"}
								borderRadius={"12px"}
								justifyContent={"space-between"}>
								<Box
									display={"flex"}
									gap={"10px"}
									alignItems={"center"}
									justifyContent={"center"}>
									<img src={weight} alt="Height" />{" "}
									<TextCus
										width={languages.value === "vi" ? "75px" : "40px"}
										fontWeight={"600"}
										fontSize={fontSize.xs}
										color={mainColor.mainBlack}>
										Weight
									</TextCus>
								</Box>
								<SliderRangeCus
									setVSlider={(v) => setValueWeight(v)}
									valueDefault={valueWeight}
									maxValue={125}
									minValue={45}
								/>
								<Box width={"154px"} height={"33px"} display={"flex"}>
									<Box
										width={"77px"}
										borderRadius={"12px 0 0 12px"}
										component={"input"}
										padding={"5px"}
										textAlign={"center"}
										fontWeight={"600"}
										max={125}
										min={0}
										value={valueWeight + ""}
										onChange={(e) =>
											+e.target.value <= 125 && setValueWeight(+e.target.value)
										}
										type="number"
										border={"1px solid #B2B2B2"}
									/>
									<Box
										width="77px"
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}
										borderRadius={"0 12px 12px 0"}
										bgcolor={"white"}
										color={"#A3A3A3"}
										fontSize={fontSize.xs}
										fontWeight={"400"}>
										kg
									</Box>
								</Box>
							</Box>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"15px"}>
							<ButtonCus
								onClick={onReset}
								sx={{ width: "190px", padding: "0px !important", flex: 1 }}>
								<Box
									bgcolor={"white"}
									width={"97%"}
									borderRadius={"10px"}
									height={"90%"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}>
									<Box
										display={"flex"}
										alignItems={"center"}
										gap={"15px"}
										className="text_linear"
										fontSize={fontSize.xs}
										fontWeight={"600"}>
										{t("Reset")}
									</Box>
								</Box>
							</ButtonCus>
							<ButtonCus
								isLoading={createYourBodyShapeMutation.isPending}
								sx={{ width: "190px" }}
								onClick={createYourModelShape}>
								<TextCus
									fontWeight={"600"}
									color={mainColor.white}
									fontSize={fontSize.xs}>
									Generate
								</TextCus>
							</ButtonCus>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalCreateBodyShape;
