import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { genMeService } from "services";
import { TParams } from "types";
import { IModelParams } from "types/outfit";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useGenMe = (inView?: boolean) => {
	const useGetGenMe = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [
				QueryKeys.YOUR_GEN_ME,
				params.typeModel,
				params.limit,
				params.type,
			],
			queryFn: ({ pageParam = params.page || 1 }) =>
				genMeService.getYourGenMe({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
			enabled: inView,
		});

		const genMeData = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			genMeData,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const deleteGenMeMutation = useMutation({
		mutationFn: (genMeId: string) => genMeService.deleteYourGenMe(genMeId),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				return;
			}
			SnackbarUtils.warning(data?.message || "Delete failed");
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed!");
		},
	});

	const { mutate: generateGenMe, isPending: isPendingCreate } = useMutation({
		mutationFn: (form: IModelParams) => genMeService.createYourGenMe(form),
	});

	return {
		useGetGenMe,
		deleteGenMeMutation,
		generateGenMe,
		isPendingCreate,
	};
};
