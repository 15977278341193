import React, { memo } from "react";
import { Stepper, Step, StepLabel, StepIconProps } from "@mui/material";
import { styled } from "@mui/system";
import { Icons } from "assets";
import { TextCus } from "components/TextCus";

const StepLabelStyled = styled(StepLabel)(() => ({
	"& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active": {
		color: "#9577D9",
		text: {
			fontSize: "12px",
			fontWeight: "600",
		},
	},
	"& .MuiStepLabel-label": {
		fontWeight: "bold",
		transition: "color 0.3s, transform 0.3s",
		"& .MuiTypography-root": {
			fontSize: "13px",
		},
	},
}));

// Main component
const StepperCus = ({
	currentStep,
	steps,
}: {
	currentStep: number;
	steps: string[];
}) => {
	return (
		<Stepper
			alternativeLabel
			activeStep={currentStep}
			sx={{
				width: "100%",
				"& .MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel": {
					top: "9px",
					left: "calc(-50% + 12px)",
					right: "calc(50% + 12px)",
				},
				"& .MuiStepConnector-line.MuiStepConnector-lineHorizontal": {
					border: "3px solid #E8E7FE",
				},
			}}>
			{steps.map((label, index) => (
				<Step key={label}>
					<StepLabelStyled StepIconComponent={ColorStepIcon}>
						<TextCus
							sx={{
								color: "#5B3E9B",
								fontWeight: index === currentStep ? "700" : "400",
							}}>
							{label}
						</TextCus>
					</StepLabelStyled>
				</Step>
			))}
		</Stepper>
	);
};

export default memo(StepperCus, (prev, next) => {
	return prev.currentStep === next.currentStep;
});

const ColorStepIconRoot = styled("div")<{
	ownerState: { completed?: boolean; active?: boolean };
}>(() => ({
	backgroundColor: "#ffffff",
	zIndex: 1,
	color: "#050358",
	width: 64,
	height: 24,
	borderRadius: "12px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	border: `1px solid #9577D9`,
	fontSize: "12px",
	variants: [
		{
			props: ({ ownerState }) => ownerState.active,
			style: {
				background: "#5B3E9B",
				border: `none`,
				color: "#FFF",
			},
		},
		{
			props: ({ ownerState }) => ownerState.completed,
			style: {
				background: "#34C759",
				border: `none`,
			},
		},
	],
}));

function ColorStepIcon(props: StepIconProps) {
	const { active, completed, className } = props;
	const icons: { [index: string]: React.ReactElement<unknown> } = {
		1: completed ? (
			<Icons.TickI />
		) : (
			<TextCus fontWeight={active ? "700" : "500"}>Step 1</TextCus>
		),
		2: completed ? (
			<Icons.TickI />
		) : (
			<TextCus fontWeight={active ? "700" : "500"}>Step 2</TextCus>
		),
		3: completed ? (
			<Icons.TickI />
		) : (
			<TextCus fontWeight={active ? "700" : "500"}>Step 3</TextCus>
		),
		4: completed ? (
			<Icons.TickI />
		) : (
			<TextCus fontWeight={active ? "700" : "500"}>Step 4</TextCus>
		),
	};
	return (
		<ColorStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorStepIconRoot>
	);
}
