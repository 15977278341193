import { Box, CircularProgress } from "@mui/material";
import { Icons, kc2 } from "assets";
import { TextCus } from "components/TextCus";
import { useDownloadImage } from "hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fontSize, mainColor } from "theme";
import { APP_ROOT } from "utils/routes";

interface IDownloadDropdown {
	dropdownRef: React.RefObject<HTMLDivElement>;
	setIsOpen: (v: boolean) => void;
	isOpen: boolean;
	imgDownload: string;
	name: string;
}

const DownloadDropdown = ({
	dropdownRef,
	setIsOpen,
	isOpen,
	imgDownload,
	name,
}: IDownloadDropdown) => {
	const navigate = useNavigate();
	const { handleDownLoadImg, isLoadingDlImg } = useDownloadImage();
	return (
		<Box
			ref={dropdownRef}
			position={"absolute"}
			top={"10px"}
			right={"10px"}
			onClick={() => setIsOpen(true)}
			bgcolor={mainColor.white}
			height={"36px"}
			width={"36px"}
			borderRadius={"10px"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			sx={{
				cursor: "pointer",
			}}>
			{isLoadingDlImg ? (
				<CircularProgress
					sx={{
						height: "30px !important",
						width: "30px !important",
					}}
				/>
			) : (
				<Icons.Download />
			)}
			{/* Dropdown download */}
			<Box
				bgcolor={mainColor.white}
				borderRadius={"12px"}
				position={"absolute"}
				top={isOpen ? "43px" : "20px"}
				sx={{
					opacity: isOpen ? 1 : 0,
					transition: "all 0.25s ease-in-out",
				}}
				width={"249px"}
				padding={"10px"}
				right={0}>
				<Box
					onClick={() =>
						imgDownload &&
						handleDownLoadImg(imgDownload, `${name || "name"}.png`)
					}
					display={"flex"}
					padding={"10px 0"}
					border={"1px solid #B2B2B2"}
					borderRadius={"10px"}
					sx={{ cursor: "pointer", textDecoration: "none" }}
					gap={"3px"}
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}>
					<TextCus
						fontSize={fontSize.xs}
						fontWeight={"600"}
						color={mainColor.textMain}>
						Free download
					</TextCus>
					<TextCus
						fontSize={"12px"}
						fontWeight={"400"}
						color={mainColor.textGray}>
						Included the watermark
					</TextCus>
				</Box>
				<Box
					mt={"10px"}
					display={"flex"}
					padding={"10px 0"}
					sx={{ cursor: "pointer" }}
					borderRadius={"10px"}
					component={"div"}
					onClick={() => navigate(APP_ROOT.PLAN)}
					flexDirection={"column"}
					gap={"3px"}
					bgcolor={"#FFCB00"}
					alignItems={"center"}
					justifyContent={"center"}>
					<Box display={"flex"} alignItems={"center"} gap={"5px"}>
						<img width={20} src={kc2} alt="kc" />
						<TextCus
							fontSize={fontSize.xs}
							fontWeight={"600"}
							color={mainColor.textMain}>
							Premium download
						</TextCus>
					</Box>
					<TextCus
						fontSize={"12px"}
						fontWeight={"400"}
						color={mainColor.textGray}>
						Without the watermark
					</TextCus>
				</Box>
			</Box>
			{/* Dropdown download */}
		</Box>
	);
};

export default DownloadDropdown;
