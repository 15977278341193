/** @format */

import React from "react";
import { Avatar, Box } from "@mui/material";
import { avtDf, Icons } from "assets";
import { useUser } from "hooks";

const AvatarCus = () => {
	const { user } = useUser();
	return (
		<Box position={"relative"}>
			<Avatar
				src={user?.avatar && user?.avatar?.length > 0 ? user?.avatar : avtDf}
				alt="Avatar"
				sx={{
					display: { md: "flex" },
					width: "40px",
					height: "40px",
				}}
			/>
			{user?.plan && user?.plan !== "FREE" && (
				<Box position={"absolute"} top={"-5px"} right={"-5px"}>
					<Icons.Premium />
				</Box>
			)}
		</Box>
	);
};

export default AvatarCus;
