export type TPayloadPayment = {
	type: string;
	privatekey: string;
	publickey: string;
};

export type TPayloadOrder = {
	qty: string;
	reference_id: string;
};

export type TPayloadApprove = {
	orderID: string;
	quantity: string;
};

export type ResponsePlan = {
	description: Array<string>;
	packageName: string;
	price: number;
	priceSale: number;
	sortType: number;
	subTitle: string;
	unit: string;
	_id: string;
};

export type TDetailBill = {
	totalCredit: number;
	invoiceId: string;
	startDate: string;
	amountSpent: number;
};

export enum EPricingPlan {
	ANNUAL = "Yearly",
	MONTHLY = "Monthly",
}

export enum STATUS_PAYMENT {
	ANNUAL = "ANNUAL",
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
}

export type TTransaction = {
	startDate: string;
	invoice: string;
	credit: number;
	amount: number;
	expiredDate: string;
	totalBill: number;
	paymentFee: number;
	netAmount: number;
	paymentMethod: string;
	plan: string;
	price: number;
};

export type TFormCustom = {
	company: string;
	email: string;
	yourRole: string;
	firstName: string;
	lastName?: string;
	message: string;
	phone: string;
	country: string;
	options: string[];
};
