/** @format */

import React from "react";
import {
	Banner,
	BoxLink,
	CommunityGeneration,
	PresetModels,
	SlideBanner,
	YourGenerations,
	YourModels,
} from "./components";
import { Box } from "@mui/material";
import { useUser } from "hooks";

const Dashboard = () => {
	const { isLogged, user } = useUser();
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			sx={{
				gap: {
					xs: "0px",
					md: "30px",
				},
			}}
			paddingBottom={"100px"}>
			<SlideBanner />
			{isLogged && user && user?.remainCode > 0 && <BoxLink />}
			<Banner />
			{isLogged && <YourGenerations />}
			{isLogged && <YourModels />}
			<PresetModels />
			<CommunityGeneration />
		</Box>
	);
};

export default Dashboard;
