import { useState } from "react";
import { userService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";

export const useDownloadImage = () => {
	const [isLoadingDlImg, setIsLoadingDlImg] = useState(false);

	const handleDownLoadImg = async (urlImg: string, imgName) => {
		console.log("URL IMG ===> ", urlImg);
		setIsLoadingDlImg(true);
		try {
			const res = await userService.getBlobImg(urlImg);
			const blob = new Blob([res.data]);
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = imgName || "image.png";
			document.body.appendChild(a);
			a.click();
			setIsLoadingDlImg(false);
		} catch (error) {
			SnackbarUtils.warning("Download error!");
			setIsLoadingDlImg(false);
		}
	};

	return { handleDownLoadImg, isLoadingDlImg };
};
