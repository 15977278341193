import { Box } from "@mui/material";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { fontSize, mainColor } from "theme";

interface ITabCus {
	setValueTab: (v: string) => void;
	arrTab: { title: string; value: string }[];
	indexActive?: number;
}

const TabCus = ({ setValueTab, arrTab, indexActive }: ITabCus) => {
	const [idx, setIdx] = useState(indexActive ?? 0);
	const { t } = useTranslation();

	return (
		<Box
			display={"flex"}
			position={"relative"}
			alignItems={"center"}
			borderBottom={"4px solid #e1dcdc"}
			justifyContent={"space-between"}>
			{arrTab?.map((item, index) => {
				return (
					<Box
						key={item.value + index}
						height={"35px"}
						sx={{ cursor: "pointer", transition: "all 0.25s ease-in-out" }}
						flex={1}
						className={index === idx ? "text_linear" : ""}
						component={"div"}
						onClick={() => {
							setValueTab(item.value);
							setIdx(index);
						}}
						fontSize={fontSize.xs}
						fontWeight={"400"}
						textAlign={"center"}>
						{t(item.title)}
					</Box>
				);
			})}

			<Box
				left={`calc(100% / (${arrTab.length} / ${idx}))`}
				bottom={"-3px"}
				width={`calc(100% / ${arrTab.length})`}
				position={"absolute"}
				height={"4px"}
				sx={{
					background: mainColor.bgLinear,
					transition: "all 0.25s ease-in-out",
				}}
			/>
		</Box>
	);
};

export default memo(
	TabCus,
	(prev, next) =>
		prev.arrTab === next.arrTab && prev?.indexActive === next?.indexActive,
);
