import { Box } from "@mui/material";
import { bgSuggest, Icons } from "assets";
import { TextCus } from "components";
import { useStoreBodyShape } from "hooks";
import React from "react";
import { fontSize } from "theme";
import { EModalBodyShape } from "types";

const arrContent = [
	"Customize your unique measurements",
	"Design your AI version fast and accurate",
	"Use your body shape with different features in StyleGen",
];

const BannerSuggest = () => {
	const { setShowModelCreate } = useStoreBodyShape();

	return (
		<Box
			bgcolor={"#A3A3A3"}
			borderRadius={"12px"}
			display={"flex"}
			flexDirection={"column"}
			width={"100%"}
			height={"224px"}
			padding={"15px"}
			sx={{
				overflowY: "auto",
				overflowX: "hidden",
				backgroundImage: `url(${bgSuggest})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "228px",
			}}>
			<Box
				display={"flex"}
				width={"100%"}
				alignItems={"center"}
				justifyContent={"space-between"}
				mb={"10px"}>
				<TextCus fontSize={fontSize.md} fontWeight={"700"} color={"#050358"}>
					CREATE YOUR BODY SHAPE
				</TextCus>
			</Box>
			<Box
				padding={"10px 20px"}
				display={"flex"}
				flexDirection={"column"}
				gap={"10px"}>
				{arrContent.map((item, index) => {
					return (
						<Box
							key={index}
							display={"flex"}
							gap={"10px"}
							alignItems={"center"}>
							{Icons.Check()}{" "}
							<TextCus
								fontSize={fontSize.xs}
								fontWeight={"400"}
								color={"#050358"}>
								{item}
							</TextCus>
						</Box>
					);
				})}
				<Box
					component={"div"}
					onClick={() => setShowModelCreate(EModalBodyShape.CREATE)}
					bgcolor={"rgba(255, 255, 255, 0.7)"}
					mt={"10px"}
					sx={{
						transition: "all 0.25s ease-in-out",
						cursor: "pointer",
						"&:hover": {
							opacity: 0.8,
						},
					}}
					width={"173px"}
					height={"36px"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					borderRadius={"12px"}>
					<TextCus
						fontWeight={"500"}
						color={"rgba(59, 56, 153, 1)"}
						fontSize={"15px"}>
						Create now
					</TextCus>
				</Box>
			</Box>
		</Box>
	);
};

export default BannerSuggest;
