/** @format */

import React, { useCallback } from "react";
import { Box, Divider } from "@mui/material";
import { AvatarCus, MultiLanguage, TextCus } from "components";
import { fontSize, mainColor } from "theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useLayout, useUser } from "hooks";
import { EModelAuth } from "types";
import { arrMenu } from "utils";

const Sidebar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, isLogged } = useUser();
	const { setOpenModalAuth } = useLayout();

	const renderMenu = useCallback(() => {
		return arrMenu.map((item, index) => (
			<Box key={item.text}>
				{index === 4 && <Divider />}
				<Box
					sx={{
						cursor: "pointer",
						transition: "all 0.15s ease-in-out",
						":hover": {
							bgcolor: "rgb(149 119 217 / 9%)",
						},
						marginTop: index === arrMenu.length - 2 ? "20px" : "0px",
					}}
					position={"relative"}
					component={"div"}
					display={"flex"}
					alignItems={"center"}
					height={"56px"}
					borderRadius={"12px"}
					bgcolor={
						location.pathname === item.link ? mainColor.bgButton : "transparent"
					}
					paddingLeft={"20px"}
					onClick={() => {
						if (!isLogged) return setOpenModalAuth(EModelAuth.LOGIN);
						navigate(item.link);
					}}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						sx={{
							transition: "all 0.25s ease-in-out",
						}}
						gap={"20px"}>
						{item.icon}
						<TextCus fontSize={fontSize.xs} fontWeight={"500"}>
							{item.text}
						</TextCus>
					</Box>
				</Box>
			</Box>
		));
	}, [location.pathname, isLogged]);

	return (
		<Box
			flexDirection={"column"}
			justifyContent={"space-between"}
			height={"100%"}
			sx={{
				display: {
					xs: "none",
					md: "flex",
				},
			}}>
			<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
				<Box alignItems={"center"} gap={"15px"} display={"flex"} my={"20px"}>
					<AvatarCus />
					{user ? (
						<Box
							display={"flex"}
							flexDirection={"column"}
							width={"calc(100% - 55px)"}>
							<TextCus
								fontSize={fontSize.sm}
								color={mainColor.textMain}
								className="res_text"
								fontWeight={"600"}>
								{user?.userName}
							</TextCus>
							<TextCus
								fontSize={fontSize.xs}
								color={mainColor.textMain}
								className="res_text"
								fontWeight={"400"}>
								{user?.email}
							</TextCus>
						</Box>
					) : (
						<TextCus fontSize={fontSize.xs}>Your name</TextCus>
					)}
				</Box>
				{renderMenu()}
			</Box>

			<Box
				paddingLeft={"20px"}
				display={"flex"}
				flexDirection={"column"}
				gap={"10px"}>
				<MultiLanguage />
				<Divider sx={{ mt: "10px" }} />
				<Box
					component={"a"}
					target="blank"
					href="https://stylegen.io/assets/PRIVACY-POLICY-STYLEGEN.pdf">
					<TextCus
						fontSize={"12px"}
						color={mainColor.textGray}
						fontWeight={"500"}>
						Privacy Policy
					</TextCus>
				</Box>
				<Box
					component={"a"}
					target="blank"
					href="https://stylegen.io/assets/TERMS-AND-CONDITIONS-STYLEGEN.pdf">
					<TextCus
						fontSize={"12px"}
						color={mainColor.textGray}
						fontWeight={"500"}>
						Terms and Conditions
					</TextCus>
				</Box>
			</Box>
		</Box>
	);
};

export default Sidebar;
