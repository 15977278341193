/** @format */
import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
	BoxPhoto,
	ButtonGenerate,
	BoxResult,
	ButtonAction,
} from "./components";
import {
	ModalNotBalance,
	ModalProcessFail,
	ModalWarningProcess,
	StepperCus,
} from "components";
import { EDropType, EnumStatusOutfit, IFinalOutfitResult } from "types/outfit";
import {
	useAITools,
	useAnimateStore,
	useGenMeStore,
	useLayout,
	useModel,
	useOutfitStore,
	useProfile,
	useStyleHairStore,
	useTrackingProcess,
	useUser,
} from "hooks";
import { Icons, upHair } from "assets";
import { useSocket } from "contexts";
import { IUser, StatusRequest, TypeServiceName } from "types";
import { IStyleHairParams } from "types/styleHair";
import { APP_ROOT } from "utils/routes";
import { useNavigate } from "react-router-dom";

const StyleHair = () => {
	const {
		colorHair,
		modelImage,
		imageHair,
		currentStep,
		selectedFileHair,
		selectedFileModel,
		selectedFileGallery,
		setCurrentStep,
		name,
		setIsLoadingProcess,
		isLoadingProcess,
		reset,
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
	} = useStyleHairStore();
	const { setIsConfirmTerm } = useLayout();
	const { setUser, user } = useUser();
	const { getProfile } = useProfile();
	const { generateStyleHair } = useAITools();
	const { socket } = useSocket();
	const [openModalFail, setOpenModalFail] = useState(false);
	const [openModalNotCredit, setOpenModalNotCredit] = useState(false);
	const [openModalWarningProcess, setOpenModalWarningProcess] = useState(false);
	const [processing, setProcessing] = useState<IFinalOutfitResult | null>(null);
	const { useGetGalleryList } = useModel();
	const navigate = useNavigate();
	const { setIsTracking, dataTracking } = useTrackingProcess(
		processing?.galleryId as string,
	);

	const { refetch } = useGetGalleryList({
		page: 1,
		limit: 3,
		type: TypeServiceName.HAIR_SWAP,
	});

	const arrButton = [
		{
			icon: Icons.Reset(),
			title: "Reset",
			action: () => {
				reset();
				setProcessing(null);
			},
		},
		{
			icon: Icons.Undo(),
			title: "Prev",
			action: () => currentStep > 0 && setCurrentStep(currentStep - 1),
		},
		{
			icon: Icons.Redo(),
			title: "Next",
			action: () => currentStep < 2 && setCurrentStep(currentStep + 1),
		},
	];

	const {
		setCurrentStep: setCurrentStepFit,
		setSelectedFileModel: setSelectedFileModelFit,
		setModelImage: setModelImageFit,
		setSelectedFileGallery: setSelectedFileGalleryFit,
		isLoadingProcess: isLoadingProcessFit,
	} = useOutfitStore();

	const {
		setCurrentStep: setCurrentStepGenMe,
		setSelectedFileModel: setSelectedFileModelGenMe,
		setModelImage: setModelImageGenMe,
		setSelectedFileGallery: setSelectedFileGalleryGenMe,
		isLoadingProcess: isLoadingProcessMe,
	} = useGenMeStore();
	const { isLoadingProcess: isLoadingProcessAnimate } = useAnimateStore();

	const handelGenerate = useCallback(async () => {
		if (!user?.isAgree) return setIsConfirmTerm(true);
		if (user?.credits === 0) return setOpenModalNotCredit(true);
		if (isLoadingProcessMe || isLoadingProcessFit || isLoadingProcessAnimate)
			return setOpenModalWarningProcess(true);
		setUser({ ...user, credits: (user?.credits || 0) - 1 } as IUser);
		setCurrentStep(2);
		setProcessing({
			...processing,
			currentStep: 0,
			totalStep: 0,
			status: EnumStatusOutfit.STARTED,
		});
		setIsLoadingProcess(true);

		const formData = new FormData();
		formData.append("name", name + "");
		if (colorHair?.id) {
			formData.append("hairColorId", colorHair?.id);
		}

		if (typeof selectedFileHair === "string") {
			formData.append("hairStyleId", selectedFileHair);
		} else {
			formData.append("fileHairStyle", selectedFileHair as File);
		}

		if (typeof selectedFileModel === "string") {
			formData.append("modelBodyId", selectedFileModel);
		} else if (typeof selectedFileGallery === "string") {
			formData.append("galleryId", selectedFileGallery);
		} else {
			formData.append("fileModel", selectedFileModel as File);
		}

		generateStyleHair(formData as IStyleHairParams, {
			onSuccess: async (res) => {
				if (res?.data?.statusCode === 400) {
					setIsLoadingProcess(false);
					setProcessing({
						...processing,
						status: EnumStatusOutfit.FAILURE,
					});
					if (res.data?.message === "BALANCE_NOT_ENOUGH") {
						setOpenModalNotCredit(true);
					} else {
						setOpenModalFail(true);
					}
				}
			},
			onError: () => {
				setOpenModalFail(true);
				setIsLoadingProcess(false);
				getProfile();
				setProcessing({
					...processing,
					status: EnumStatusOutfit.FAILURE,
				});
			},
		});
	}, [
		selectedFileModel,
		selectedFileHair,
		selectedFileGallery,
		colorHair?._id,
		name,
		user,
	]);

	const handleActionStatus = useCallback(() => {
		setIsLoadingProcess(false);
		setCurrentStep(3);
		getProfile();
		refetch();
	}, []);

	const handleSwitchItem = useCallback(() => {
		setCurrentStep(0);
		setSelectedFileModel(null);
		setModelImage(processing?.url as string);
		setSelectedFileGallery(processing?.galleryId as string);
	}, [processing]);

	const handleNavigateStyleFit = useCallback(() => {
		setCurrentStepFit(0);
		setSelectedFileModelFit(null);
		setModelImageFit(processing?.url as string);
		setSelectedFileGalleryFit(processing?.galleryId as string);
		navigate(APP_ROOT.STYLE_FIT);
	}, [processing]);

	const handleNavigateGenMe = useCallback(() => {
		setCurrentStepGenMe(0);
		setSelectedFileModelGenMe(null);
		setModelImageGenMe(processing?.url as string);
		setSelectedFileGalleryGenMe(processing?.galleryId as string);
		navigate(APP_ROOT.GEN_ME);
	}, [processing]);

	useEffect(() => {
		if (socket) {
			socket.on(`eventHairStyle-${user?.id}`, (data) => {
				setIsLoadingProcess(true);
				console.log("==============> data", data);
				setIsTracking(true);
				setProcessing(data);
				if (data?.status === EnumStatusOutfit.SUCCESS) {
					handleActionStatus();
					setIsTracking(false);
				}
				if (data?.status === EnumStatusOutfit.FAILURE) {
					setIsLoadingProcess(false);
					setIsTracking(false);
					getProfile();
					setOpenModalFail(true);
				}
			});
			return () => {
				socket.off(`eventHairStyle-${user?.id}`);
			};
		}
	}, [socket, user]);

	return (
		<Box height={"100%"}>
			<Box
				height={"100px"}
				sx={{
					height: {
						xs: "120px",
						xl: "150px",
					},
				}}
				width={"100%"}
				display={"flex"}
				alignItems={"center"}>
				<StepperCus
					currentStep={currentStep}
					steps={[
						"Upload Your Photo",
						"Upload Chosen Hairstyle",
						"Generate",
						"See Your Result",
					]}
				/>
			</Box>
			<Box
				width={"90%"}
				margin={"auto"}
				sx={{
					height: { xs: "calc(100vh - 198px)", xl: "calc(100vh - 228px)" },
				}}>
				<Grid
					container
					sx={{
						maxWidth: {
							xs: "823px",
							xl: "1200px",
						},
						margin: "auto",
					}}
					spacing={2}
					height={"100%"}>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							padding: {
								xs: "0px !important",
								md: "16px 0 0 16px !important",
							},
							height: {
								xs: "170px",
								md: "100%",
							},
						}}>
						<Box
							display={"flex"}
							gap={"20px"}
							sx={{
								flexDirection: { xs: "row", md: "column" },
								height: {
									xs: "unset",
									md: "95%",
								},
							}}
							justifyContent={"center"}
							alignItems={"center"}>
							<BoxPhoto
								dropType={EDropType.BODY}
								title="Your Photo"
								description="Human photo will be shown here"
								initialImage={modelImage as string}
								active={currentStep === 0}
								currentStep={1}
								action={() => setCurrentStep(0)}
							/>
							<BoxPhoto
								dropType={EDropType.OUTFIT}
								title="Chosen Hairstyle"
								description="Chosen hairstyle will be shown here"
								active={currentStep === 1}
								initialImage={imageHair as string}
								currentStep={2}
								action={() => setCurrentStep(1)}
								imgDf={upHair}
								imgColor={colorHair?.image}
							/>
							<Box
								display={"flex"}
								flexDirection={"column"}
								gap={"20px"}
								alignItems={"center"}
								width={"100%"}
								justifyContent={"center"}>
								<ButtonGenerate
									handelGenerate={handelGenerate}
									disabled={
										(!selectedFileGallery && !selectedFileModel) ||
										!selectedFileHair ||
										isLoadingProcess
									}
									currentStep={3}
									active={currentStep === 2}
								/>
								<Box
									display={"flex"}
									width={"100%"}
									alignItems={"center"}
									gap={"5px"}
									justifyContent={"space-between"}>
									{arrButton.map((item) => (
										<ButtonAction
											icon={item.icon}
											text={item.title}
											action={item.action}
											key={item.title}
										/>
									))}
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={8}
						sx={{
							height: {
								xs: "calc(100% - 180px)",
								md: "100%",
							},
							padding: {
								xs: "0px !important",
								md: "16px 0 0 16px !important",
							},
						}}>
						<BoxResult
							imgDownload={processing?.imgDownload || ""}
							initialImage={
								processing?.message === "SUCCESS"
									? (processing.url as string)
									: dataTracking?.status === StatusRequest.SUCCESS
									? (dataTracking?.image as string)
									: ""
							}
							valueProcess={
								processing?.currentStep && processing?.totalStep
									? (processing?.currentStep / processing?.totalStep) * 100
									: dataTracking?.status === StatusRequest.SUCCESS
									? 100
									: 0
							}
							isLoading={isLoadingProcess}
							isStart={processing?.status === EnumStatusOutfit.STARTED}
							handleSwitchItem={handleSwitchItem}
							handleNavigateStyleFit={handleNavigateStyleFit}
							handleNavigateGenMe={handleNavigateGenMe}
							processing={processing}
						/>
					</Grid>
				</Grid>
			</Box>
			{openModalFail && (
				<ModalProcessFail
					open={openModalFail}
					onClose={() => setOpenModalFail(false)}
				/>
			)}
			{openModalNotCredit && (
				<ModalNotBalance
					open={openModalNotCredit}
					onClose={() => setOpenModalNotCredit(false)}
				/>
			)}
			{openModalWarningProcess && (
				<ModalWarningProcess
					open={openModalWarningProcess}
					onClose={() => setOpenModalWarningProcess(false)}
				/>
			)}
		</Box>
	);
};

export default StyleHair;
