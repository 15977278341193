import React from "react";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { ButtonCus, TextCus } from "components";
import { Icons } from "assets";
import { CloseOutlined } from "@mui/icons-material";
import { useCopyClipboard, useUser } from "hooks";
import { useTranslation } from "react-i18next";

interface IModalLinkReferral {
	open: boolean;
	onClose: () => void;
}

const ModalLinkReferral = ({ open, onClose }: IModalLinkReferral) => {
	const { isCopied, staticCopy } = useCopyClipboard();
	const { user } = useUser();
	const { t } = useTranslation();

	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "rgba(0, 0, 0, 0.6)",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					position={"relative"}
					justifyContent={"space-between"}
					gap={"20px"}
					padding={"24px 20px"}
					flexDirection={"column"}
					height={"300px"}
					sx={{
						width: {
							xs: "620px",
						},
					}}
					borderRadius={"10px"}
					bgcolor={mainColor.white}>
					<IconButton
						sx={{ position: "absolute", top: 0, right: 0 }}
						onClick={onClose}>
						<CloseOutlined />
					</IconButton>
					<Box
						display={"flex"}
						mt={"30px"}
						flexDirection={"column"}
						gap={"5px"}
						justifyContent={"center"}
						alignItems={"center"}>
						<Box display={"flex"} alignItems={"end"} width={"100$"} gap={"4px"}>
							<TextCus fontWeight={"600"} fontSize={fontSize.sm}>
								Get
							</TextCus>
							<Box
								className="text_linear"
								fontWeight={"600"}
								fontSize={fontSize.sm}>
								{t("+ 14 Days Free Trial")}
							</Box>
							<TextCus fontWeight={"600"} fontSize={fontSize.sm}>
								for Each Referral
							</TextCus>
						</Box>
						<Box mt={"5px"} maxWidth={"500px"} textAlign={"center"}>
							<TextCus fontSize={"12px"} color={"#666666"}>
								You can get up to 3 times of +14 days free trial when 3 separate
								friends use StyleGen through your referral link
							</TextCus>
						</Box>
					</Box>
					<Box
						display={"flex"}
						width={"100%"}
						gap={"15px"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<Box
							borderRadius={"12px"}
							display={"flex"}
							padding={"0 15px"}
							gap={"15px"}
							maxWidth={"524px"}
							alignItems={"center"}
							height={"40px"}
							bgcolor={"rgba(232, 231, 254, 0.7)"}
							flex={"1"}>
							<Icons.Link />
							<TextCus
								className="res_text"
								color={"#303030"}
								fontSize={fontSize.xs}
								fontWeight={"600"}>
								{process.env.REACT_APP_URL +
									`register?_referralCode=${user?.referralCode}`}
							</TextCus>
						</Box>
						<IconButton
							onClick={() =>
								staticCopy(
									process.env.REACT_APP_URL +
										`register?_referralCode=${user?.referralCode}`,
								)
							}>
							{Icons.Copy(isCopied ? "green" : "#121219")}
						</IconButton>
					</Box>
					<ButtonCus
						title="Complete"
						sx={{ mt: "10px" }}
						onClick={() => {
							onClose();
						}}
					/>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalLinkReferral;
