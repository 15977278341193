/** @format */

import React from "react";
import { Box, Modal } from "@mui/material";
import { appStore, bgMobile, googlePlay } from "assets";
import { TextCus } from "components/TextCus";

const ModalCheckMobile = () => {
	return (
		<Modal
			keepMounted
			open={true}
			sx={{
				zIndex: 9999,
				display: { xs: "flex", md: "none" },
				alignItems: "center",
				justifyContent: "center",
				overflowY: "auto",
				overflowX: "hidden",
				backgroundImage: `url(${bgMobile})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100vh",
				".MuiBackdrop-root.MuiModal-backdrop": {
					bgcolor: "transparent",
				},
			}}
			onClose={() => console.log("object")}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"end"}
				border={"1px solid #fff"}
				borderRadius={"10px"}
				padding="0 0 5px 0"
				flexDirection={"column"}
				width={"90vw"}
				sx={{ outline: "none", background: "#fff" }}>
				<TextCus
					sx={{
						width: "100%",
						padding: "10px",
						fontSize: "14px",
						fontWeight: "700",
						color: "black",
						textAlign: "center",
					}}
					id="keep-mounted-modal-title">
					StyleGen is currently only available for desktop version
				</TextCus>
				<Box
					padding={"10px"}
					width={"100%"}
					display={"flex"}
					alignItems={"center"}
					gap={"10px"}
					justifyContent={"center"}>
					<img width={"44%"} src={googlePlay} alt="Google Play" />
					<img width={"44%"} src={appStore} alt="Google Play" />
				</Box>
			</Box>
		</Modal>
	);
};

export default ModalCheckMobile;
