/** @format */
import React, { memo, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TabCus, TextCus } from "components";
import { fontSize, mainColor } from "theme";
import BoxUpload from "./BoxUpload";
import { PresetModel } from "./PresetModel";
import { arrTab, arrTabStepTwoGenMe } from "utils";
import { Gallery } from "./Gallery";
import { useGenMeStore } from "hooks";
import { YourModel } from "./YourModel";
import { YourFeatures } from "./YourFeatures";
import PresetFeatures from "./PresetFeatures";
import CreateNowBody from "components/Common/CreateNowBody";

const Sidebar = () => {
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		currentStep,
		setSelectedFileGenMe,
		setImageMe,
	} = useGenMeStore();
	const [itemTab, setItemTab] = useState("UPLOAD");

	const renderSidebar = useCallback(() => {
		switch (currentStep) {
			case 0:
				return (
					<Box flex={1}>
						<Box height={"50px"}>
							<TabCus arrTab={arrTab} setValueTab={(v) => setItemTab(v)} />
						</Box>
						<Box padding={"10px 15px"}>
							<CreateNowBody />
						</Box>
						<Box sx={{ height: "calc(100vh - 305px)" }}>
							{itemTab === "UPLOAD" ? (
								<Box padding={"15px"} height={"100%"}>
									<BoxUpload
										onFileSelect={(file: File) => {
											setSelectedFileGallery(null);
											setSelectedFileModel(file);
											setModelImage(URL.createObjectURL(file));
										}}
										onClear={() => setModelImage("")}
									/>
								</Box>
							) : (
								<Box
									display={"flex"}
									gap={"20px"}
									padding={"15px"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<YourModel />
									<PresetModel />
									<Gallery />
								</Box>
							)}
						</Box>
					</Box>
				);
			default:
				return (
					<Box flex={1}>
						<Box height={"50px"}>
							<TabCus
								arrTab={arrTabStepTwoGenMe}
								setValueTab={(v) => setItemTab(v)}
							/>
						</Box>

						<Box sx={{ height: "calc(100vh - 230px)" }}>
							{itemTab === "UPLOAD" ? (
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									flexDirection={"column"}
									padding={"15px"}
									sx={{ overflowY: "auto" }}
									gap={"20px"}
									height={"100%"}>
									<BoxUpload
										onFileSelect={(file) => {
											setSelectedFileGenMe(file);
											setImageMe(URL.createObjectURL(file));
										}}
										onClear={() => setImageMe("")}
									/>
								</Box>
							) : (
								<Box
									display={"flex"}
									gap={"20px"}
									padding={"15px"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<PresetFeatures />
									<YourFeatures />
								</Box>
							)}
						</Box>
					</Box>
				);
		}
	}, [currentStep, itemTab]);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"space-between"}
			position={"relative"}
			height={"100%"}>
			<Box
				display={"flex"}
				paddingX={"15px"}
				flexDirection={"column"}
				gap={"2px"}
				justifyContent={"center"}
				height={"98px"}>
				<TextCus
					fontSize={fontSize.sm}
					color={mainColor.secondary}
					fontWeight={"700"}>
					{currentStep === 0 ? "PRIMARY PHOTO" : "YOUR STATION"}
				</TextCus>
				<TextCus fontSize={"12px"} color={"#FF52F0"} fontWeight={"500"}>
					{currentStep === 0
						? `Upload your photos or choose one model in “Use Model” tab`
						: `Upload your photo or choose in “Desired Face” tab`}
				</TextCus>
			</Box>
			{renderSidebar()}
		</Box>
	);
};

export default memo(Sidebar);
