import { Box } from "@mui/material";
import { maintain, notFound } from "assets";
import { TextCus } from "components";
import { Header } from "pages/admin/dashboard/components";
import React from "react";

const Maintain = () => {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"space-between"}
			sx={{
				overflowY: "auto",
				overflowX: "hidden",
				backgroundImage: `url(${notFound})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100vh",
			}}>
			<Box width={"100%"}>
				<Header />
			</Box>
			<Box
				flex={1}
				gap={"20px"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}>
				<img src={maintain} alt="Maintain" />
				<TextCus fontSize={"34px"} color={"#FFFFFF"} fontWeight={"700"}>
					We’re under maintenance
				</TextCus>
				<TextCus fontSize={"16px"} color={"#FFFFFF"} fontWeight={"400"}>
					Our website is down for maintenance. We will be back shortly.
				</TextCus>
			</Box>
		</Box>
	);
};

export default Maintain;
