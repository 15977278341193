/** @format */

import { useEffect } from "react";

import { useSocket } from "contexts";
import { ENotification } from "types";
import { useUser } from "./useUser";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "utils";
import { userService } from "services";
import { APP_ROOT } from "utils/routes";
import { useNavigate } from "react-router-dom";
import { useLayout } from "./useLayout";

export const useMaintain = () => {
	const { socket } = useSocket();
	const { user } = useUser();
	const navigate = useNavigate();
	const { setOpenModalWarn } = useLayout();

	const { data } = useQuery({
		queryKey: [QueryKeys.MAINTAIN],
		queryFn: () => userService.getNotification("MAINTAIN"),
	});

	useEffect(() => {
		if (
			data?.data?.data?.isMaintain &&
			location.pathname !== APP_ROOT.MAINTAIN
		) {
			navigate(APP_ROOT.MAINTAIN);
		}
	}, [data?.data?.data?.isMaintain]);

	useEffect(() => {
		if (socket) {
			socket.on(`eventNotification-${user?.id}`, (data) => {
				if (data?.type === ENotification.MAINTAIN) {
					setOpenModalWarn(ENotification.MAINTAIN);
					if (
						location.pathname !== APP_ROOT.STYLE_FIT &&
						location.pathname !== APP_ROOT.STYLE_HAIR &&
						location.pathname !== APP_ROOT.GEN_ME &&
						location.pathname !== APP_ROOT.ANIMATE &&
						location.pathname !== APP_ROOT.MAINTAIN
					) {
						navigate(APP_ROOT.MAINTAIN);
					}
				}
			});
			return () => {
				socket.off(`eventNotification-${user?.id}`);
			};
		}
	}, [socket, location?.pathname]);
};
