import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useGenMe, useGenMeStore } from "hooks";
import { TParams } from "types";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { TextCus } from "components";
import { Icons } from "assets";

export const YourFeatures: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileGenMe,
		setImageMe,
		selectedFileGenMe,
		setCurrentStep,
	} = useGenMeStore();
	const { useGetGenMe } = useGenMe();

	// ======================== STATE ========================
	const [paramsGenMe, setParamsGenMe] = useState<TParams>({
		page: 1,
		limit: 3,
	});
	const {
		genMeData,
		isLoading,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useGetGenMe(paramsGenMe);

	useEffect(() => {
		setParamsGenMe({
			...paramsGenMe,
		});
	}, []);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Your Face" />
			<ListImage
				isLoading={isLoading}
				images={genMeData}
				selectedImage={selectedFileGenMe as string}
				setSelectedImage={(it) => {
					setSelectedFileGenMe(it.id as string);
					setImageMe(it.image as string);
					setCurrentStep(2);
				}}
				dropType={EDropType.OUTFIT}
			/>
			{hasNextPage && (
				<Box
					component={"div"}
					onClick={() => fetchNextPage()}
					width={"fit-content"}
					sx={{ cursor: "pointer" }}
					margin={"0 auto"}
					padding={"4px 10px"}
					borderRadius={"12px"}
					bgcolor={"rgba(149, 119, 217, 0.2)"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"15px"}>
					<TextCus>See more</TextCus>
					{isFetchingNextPage ? (
						<CircularProgress size={15} />
					) : (
						<Icons.ArrowSelect />
					)}
				</Box>
			)}
		</Box>
	);
};
