import { useInfiniteQuery } from "@tanstack/react-query";
import { modelService } from "services";
import { TParams } from "types";
import { QueryKeys } from "utils";

export const usePresetModel = () => {
	const useGetPresetModel = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [
				QueryKeys.PRESET_MODEL,
				params.page,
				params.limit,
				params.type,
			],
			queryFn: ({ pageParam = params.page || 1 }) =>
				modelService.getPresetModel({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const presetModel = data?.pages.flatMap((page) => page.data) ?? [];

		return {
			presetModel,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	return {
		useGetPresetModel,
	};
};
