import { useCallback, useState } from "react";
import { img404, imgLoad } from "assets";

export const useLoadImage = () => {
	// =========> Handle load image <=========

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const handleLoad = useCallback(() => {
		setLoading(false);
	}, []);
	const handleError = useCallback(() => {
		setLoading(false);
		setError(true);
	}, []);

	// =========> End handle load image <=========

	return { handleLoad, handleError, loading, error, imgLoad, img404 };
};
