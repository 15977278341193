/** @format */

import React, { useState } from "react";
import { Box, Checkbox, Fade, IconButton, Modal } from "@mui/material";
import { TextCus } from "components/TextCus";
import { ButtonCus } from "components/ButtonCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import { Icons } from "assets";
import { KEY_CONTEXT } from "utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";

interface IModalProfile {
	open: boolean;
	onClose: () => void;
	handleClick: () => void;
	currentStep: number;
	arrContentShould: {
		img: string;
		title: string;
	}[];
	arrContentNotShould: {
		img: string;
		title: string;
	}[];
}

const ModalRequirements = ({
	open,
	onClose,
	handleClick,
	currentStep,
	arrContentShould,
	arrContentNotShould,
}: IModalProfile) => {
	const [checked, setChecked] = useState(false);
	const location = useLocation();

	const handleChange = (event) => {
		if (event.target.checked) {
			localStorage.setItem(
				KEY_CONTEXT.MODAL_REQUIRE + currentStep + location.pathname,
				"true",
			);
		} else {
			localStorage.removeItem(
				KEY_CONTEXT.MODAL_REQUIRE + currentStep + location.pathname,
			);
		}
		setChecked(event.target.checked);
	};

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={mainColor.white}
					flexDirection={"column"}
					paddingX={"30px"}
					paddingY={"20px"}
					sx={{
						maxWidth: "680px",
						maxHeight: "423px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"764px"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus fontWeight={"700"} fontSize={fontSize.sm}>
							Photo Requirements
						</TextCus>
						<IconButton onClick={onClose}>
							<CloseOutlined />
						</IconButton>
					</Box>
					<Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
						<Box display={"flex"} gap={"10px"} flexDirection={"column"}>
							<Box display={"flex"} alignItems={"center"} gap={"10px"}>
								{Icons.Should()}
								<TextCus>Should be</TextCus>
							</Box>
							<Box gap={"10px"} display={"flex"}>
								{arrContentShould.map((item) => (
									<Box
										display={"flex"}
										gap={"5px"}
										flexDirection={"column"}
										justifyContent={"center"}
										alignItems={"center"}
										key={item.title}>
										<LazyLoadImage src={item.img} alt="avt" />
										<TextCus
											fontSize={"12px"}
											lineHeight={"15px"}
											textAlign={"center"}
											fontWeight={"400"}
											maxWidth={"100px"}>
											{item.title}
										</TextCus>
									</Box>
								))}
							</Box>
						</Box>

						<Box display={"flex"} gap={"10px"} flexDirection={"column"}>
							<Box display={"flex"} alignItems={"center"} gap={"10px"}>
								{Icons.NotShould()}
								<TextCus>Should not be</TextCus>
							</Box>
							<Box gap={"10px"} display={"flex"} alignItems={"flex-start"}>
								{arrContentNotShould.map((item) => (
									<Box
										display={"flex"}
										gap={"5px"}
										flexDirection={"column"}
										justifyContent={"center"}
										alignItems={"center"}
										key={item.title}>
										<LazyLoadImage src={item.img} alt="avt" />
										<TextCus
											fontSize={"12px"}
											lineHeight={"15px"}
											textAlign={"center"}
											fontWeight={"400"}
											maxWidth={"100px"}>
											{item.title}
										</TextCus>
									</Box>
								))}
							</Box>
						</Box>
					</Box>

					<Box
						display={"flex"}
						justifyContent={"start"}
						width={"100%"}
						alignItems={"center"}>
						<Checkbox
							checked={checked}
							onChange={handleChange}
							inputProps={{ "aria-label": "controlled" }}
						/>
						<TextCus fontSize={"12px"} fontWeight={"500"}>
							Don’t show again
						</TextCus>
					</Box>

					<ButtonCus title="Upload Photo" onClick={handleClick} />
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalRequirements;
