import { Box } from "@mui/material";
import { Icons } from "assets";
import React from "react";

const BoxSubtraction = ({ dia = -1 }: { dia?: number }) => {
	return (
		<Box
			fontSize={"14px"}
			bgcolor={"#FFCB00"}
			position={"absolute"}
			top={0}
			padding={"0 2px"}
			gap={"2px"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			borderRadius={"0 0 0 12px"}
			color={"#534200"}
			right={0}>
			{dia} {Icons.DiamondBtn()}
		</Box>
	);
};

export default BoxSubtraction;
