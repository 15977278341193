import { Box } from "@mui/material";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import React from "react";
import { mainColor } from "theme";

const BoxTrial = () => {
	const { user } = useUser();

	return (
		<Box
			position={"relative"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"flex-end"}
			borderRadius={"30px"}
			width={"155px"}
			overflow={"hidden"}
			padding={"2px"}
			height={"41px"}
			sx={{ background: mainColor.bgLinear }}>
			<Box
				bgcolor={"#FFF5FF"}
				display={"flex"}
				borderRadius={"30px"}
				padding={"0 10px"}
				alignItems={"center"}
				justifyContent={"flex-end"}
				width={"100%"}
				height={"100%"}>
				<Box
					position={"absolute"}
					left={"21px"}
					width={"41px"}
					height={"41px"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					borderRadius={"40px"}
					fontSize={"22px"}
					fontWeight={"700"}
					color={"white"}
					sx={{
						background: mainColor.bgLinear,
						transform: "translateX(-50%)",
					}}>
					{user?.daysRemaining}
				</Box>
				<TextCus fontWeight={"500"} color={"#303030"} fontSize={"12px"}>
					days of free trial
				</TextCus>
			</Box>
		</Box>
	);
};

export default BoxTrial;
