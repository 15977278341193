/** @format */
import React, { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { TextCus } from "components";
import { fontSize, mainColor } from "theme";
import { useState } from "react";
import { TParams, TypeServiceName } from "types";
import { useModel } from "hooks";
import ListImage from "./ListImage";
import { Icons } from "assets";
import { APP_ROOT } from "utils/routes";
import { useLocation, useSearchParams } from "react-router-dom";

const Sidebar = () => {
	const { useGetGalleryList } = useModel();
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const location = useLocation();

	const [paramsGallery, setParamsGallery] = useState<TParams>({
		page: 1,
		limit: 4,
		type: TypeServiceName.OUTFIT,
	});

	useEffect(() => {
		if (location.pathname === APP_ROOT.HISTORY)
			setParamsGallery({ ...paramsGallery, type: TypeServiceName.OUTFIT });
		if (location.pathname === APP_ROOT.HISTORY_STYLE_HAIR)
			setParamsGallery({ ...paramsGallery, type: TypeServiceName.HAIR_SWAP });
		if (location.pathname === APP_ROOT.HISTORY_GEN_ME)
			setParamsGallery({ ...paramsGallery, type: TypeServiceName.GEN_ME });
		if (location.pathname === APP_ROOT.HISTORY_ANIMATE)
			setParamsGallery({ ...paramsGallery, type: TypeServiceName.ANIMATE });
	}, [location]);

	const {
		gallery: dataGallery,
		isLoading: isLoadingGallery,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
		refetch,
	} = useGetGalleryList(paramsGallery);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			position={"relative"}
			height={"100%"}>
			<Box
				display={"flex"}
				paddingX={"15px"}
				flexDirection={"column"}
				gap={"2px"}
				justifyContent={"center"}
				height={"78px"}>
				<TextCus
					fontSize={fontSize.sm}
					color={mainColor.secondary}
					fontWeight={"700"}>
					HISTORY VERSION
				</TextCus>
				<TextCus fontSize={"12px"} color={"#FF52F0"} fontWeight={"500"}>
					{location.pathname === APP_ROOT.HISTORY_STYLE_HAIR
						? "See your recent Style Hair"
						: location.pathname === APP_ROOT.HISTORY
						? "See your recent Style Fit"
						: location.pathname === APP_ROOT.HISTORY_ANIMATE
						? "See your recent Animate"
						: "See your recent Gen Me"}
				</TextCus>
			</Box>
			<Box
				display={"flex"}
				gap={"20px"}
				paddingX={"15px"}
				flexDirection={"column"}
				height={"calc(100vh - 168px)"}>
				<Box sx={{ overflowY: "auto" }}>
					<ListImage
						images={dataGallery}
						selectedImage={id + ""}
						isLoading={isLoadingGallery}
						refetch={refetch}
					/>
					{hasNextPage && (
						<Box
							component={"div"}
							onClick={() => fetchNextPage()}
							width={"fit-content"}
							sx={{ cursor: "pointer" }}
							margin={"0 auto"}
							padding={"4px 10px"}
							borderRadius={"12px"}
							bgcolor={"rgba(149, 119, 217, 0.2)"}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"15px"}>
							<TextCus>See more</TextCus>
							{isFetchingNextPage ? (
								<CircularProgress size={15} />
							) : (
								<Icons.ArrowSelect />
							)}
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Sidebar;
