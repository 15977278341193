import React, { memo } from "react";
import { Box, ImageListItem } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IModelType } from "types/outfit";
import { mainColor } from "theme";
import dayjs from "dayjs";
import { ButtonDelete } from "components";
import { useLoadImage } from "hooks";

interface IImageItem {
	handleClick?: (item: IModelType) => void;
	item: IModelType;
	selectedImage: string | undefined;
	actionDelete?: (id: string) => void;
	isLoading?: boolean;
}

const ImageItem = ({
	item,
	selectedImage,
	actionDelete,
	handleClick,
	isLoading,
}: IImageItem) => {
	const { error, loading, handleError, handleLoad, img404, imgLoad } =
		useLoadImage();

	return (
		<>
			<ImageListItem
				sx={{
					position: "relative",
					cursor: "pointer",
					transition: "all 0.25s ease-in-out",
					height: "auto",
					background:
						selectedImage === item?.id ? mainColor.bgLinear : "transparent",
					padding: "3px",
					borderRadius: "10px",
					":hover": {
						".box_history": {
							opacity: 1,
							pointerEvents: "unset",
						},
					},
				}}>
				<Box
					borderRadius={"10px"}
					display={"flex"}
					overflow={"hidden"}
					position={"relative"}
					justifyContent={"center"}>
					<Box
						display={"flex"}
						justifyContent={"center"}
						sx={{ aspectRatio: 3 / 4 }}
						width={"100%"}
						minHeight={"200px"}
						position={"relative"}>
						<LazyLoadImage
							style={{
								borderRadius: "10px",
								width: "100%",
								height: "100%",
								minHeight: "200px",
								transition: "all 0.25s ease-in-out",
								display: "block",
								objectFit: "cover",
							}}
							srcSet={`${item?.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
							loading="lazy"
							effect="blur"
							src={
								error
									? img404
									: loading
									? imgLoad
									: `${item?.image}?w=164&h=164&fit=crop&auto=format`
							}
							onLoad={handleLoad}
							onError={handleError}
							alt={error ? "Error" : item?.name}
						/>
						<Box
							position={"absolute"}
							top={0}
							left={0}
							width={"100%"}
							height={"20%"}
							component={"div"}
							display={"flex"}
							zIndex={9}
							alignItems={"center"}
							justifyContent={"center"}
							fontSize={"12px"}
							fontWeight={"500"}
							color={mainColor.white}
							sx={{
								background:
									"linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))",
							}}>
							{dayjs(item?.createdAt).format("DD MMM, hh:mm A") || ""}
						</Box>
					</Box>
					<Box
						position={"absolute"}
						top={0}
						width={"100%"}
						height={"100%"}
						sx={{
							background: "rgba(0, 0, 0, 0.3)",
							left:
								selectedImage === item?.id ? 0 : { xs: "-120px", md: "-190px" },
							transition: "all 0.25s ease-in-out",
							borderRadius: "10px",
						}}
					/>
					<Box
						width={"100%"}
						height={"100%"}
						component={"div"}
						onClick={() => handleClick?.(item)}
						position={"absolute"}
						className="box_history"
						sx={{
							background: "rgba(0, 0, 0, 0.3)",
							transition: "all 0.25s ease-in-out",
							borderRadius: "10px",
							opacity: 0,
							pointerEvents: "none",
						}}>
						<ButtonDelete
							isLoading={isLoading || false}
							actionDelete={() => actionDelete?.(item?.id + "")}
						/>
					</Box>
				</Box>
			</ImageListItem>
		</>
	);
};

export default memo(
	ImageItem,
	(prev, next) =>
		prev.item._id === next.item._id &&
		prev.isLoading === next.isLoading &&
		prev.selectedImage === next.selectedImage,
);
