/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { fontSize, mainColor } from "theme/default";
import {
	Avatar,
	Box,
	Card,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Modal,
} from "@mui/material";
import { useCopyClipboard, useProfile, useUser } from "hooks";
import { ButtonCus, InputUpdate, TabCus, TextCus } from "components";
import { CameraAltOutlined, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import dayjs from "dayjs";
import { TypeLogin } from "types";
import { Icons } from "assets";

interface IModalProfile {
	open: boolean;
	onClose: () => void;
	onOpenUpdatePass: () => void;
}

const arrSetting = [
	{ title: "Account info", value: "ACCOUNT" },
	{ title: "Current plan", value: "PLAN" },
];

const ModalProfile = ({ open, onClose, onOpenUpdatePass }: IModalProfile) => {
	const navigate = useNavigate();
	const { user } = useUser();
	const [itemTab, setItemTab] = useState("ACCOUNT");
	const { updateProfile, isPending } = useProfile();
	const [name, setName] = useState("");
	const refInput = useRef<HTMLInputElement>(null);

	const onEditProfile = useCallback(() => {
		onClose();
		onOpenUpdatePass();
	}, []);

	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const url = URL.createObjectURL(file);
			setPreviewUrl(url);
			updateProfile({ userName: user?.userName + "", avatar: file });
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};
	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	// Update user name

	const handleNameBlur = useCallback(() => {
		name?.length > 0 &&
			name !== user?.userName &&
			updateProfile({ userName: name });
	}, [name]);

	const { isCopied, staticCopy } = useCopyClipboard();

	const handleNameChange = useCallback((e) => {
		setName(e.target.value);
	}, []);

	const selectAllInlineText = useCallback((e) => {
		e.target.focus();
		e.target.select();
	}, []);

	useEffect(() => {
		setName(user?.userName + "");
	}, [user?.userName]);

	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Card
				sx={{
					width: 650,
					height: itemTab === "ACCOUNT" ? 600 : 500,
					margin: "0 auto",
					my: 5,
					border: `1px solid ${mainColor.secondary}`,
					backgroundColor: "#ffffff",
					borderRadius: "10px",
					transition: "all 0.25s ease-in-out",
				}}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					height={"100px"}>
					<TextCus fontSize={fontSize.md} fontWeight={"600"}>
						User profile
					</TextCus>
				</Box>
				<TabCus arrTab={arrSetting} setValueTab={(v) => setItemTab(v)} />
				{itemTab === "ACCOUNT" ? (
					<Box
						mt={"20px"}
						display={"flex"}
						gap={"20px"}
						alignItems={"center"}
						flexDirection={"column"}
						justifyContent={"center"}>
						<Box
							position={"relative"}
							padding={"2px"}
							sx={{ background: mainColor.bgLinear }}
							borderRadius={"50%"}>
							{isPending && (
								<Box
									position={"absolute"}
									width={"100%"}
									height={"100%"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}
									zIndex={2}>
									<CircularProgress
										sx={{
											height: "30px !important",
											width: "30px !important",
										}}
									/>
								</Box>
							)}
							<Avatar
								alt={user?.userName}
								src={previewUrl ?? user?.avatar}
								sx={{ width: 80, height: 80 }}
							/>
							<IconButton
								onClick={handleClick}
								sx={{
									position: "absolute",
									bottom: 0,
									right: "1px",
									bgcolor: "white",
									border: "1px solid #ccc",
									padding: "3px",
								}}>
								<CameraAltOutlined sx={{ width: "20px", height: "20px" }} />
							</IconButton>
							<input
								type="file"
								ref={fileInputRef}
								style={{ display: "none" }}
								accept=".png, .jpg, .jpeg, .webp"
								onChange={handleFileChange}
							/>
						</Box>

						<Box
							alignItems={"center"}
							display={"flex"}
							flexDirection={"column"}
							gap={"20px"}
							height={"100%"}
							justifyContent={"space-between"}
							padding={"20px"}
							width={"80%"}>
							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={3}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											Name
										</TextCus>
									</Grid>
									<Grid position={"relative"} item xs={9}>
										<InputUpdate
											refInput={refInput}
											valueTitle={name}
											handleGalleryTitleBlur={handleNameBlur}
											handleGalleryTitleChange={handleNameChange}
											selectAllInlineText={selectAllInlineText}
										/>
										<IconButton
											onClick={() => refInput.current?.click()}
											sx={{ position: "absolute", right: 0, top: "-10px" }}>
											<Edit sx={{ fontSize: "20px" }} />
										</IconButton>
									</Grid>
								</Grid>
							</Box>
							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={3}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											ID
										</TextCus>
									</Grid>
									<Grid item xs={9}>
										<TextCus fontSize={fontSize.xs}>{user?.id}</TextCus>
									</Grid>
								</Grid>
							</Box>

							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={3}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											Email
										</TextCus>
									</Grid>
									<Grid item xs={9}>
										<TextCus fontSize={fontSize.xs}>{user?.email}</TextCus>
									</Grid>
								</Grid>
							</Box>
							{user && user?.remainCode > 0 && (
								<Box width={"100%"}>
									<Divider sx={{ marginBottom: "15px" }} />
									<Grid container>
										<Grid item xs={12} mb={"10px"}>
											<TextCus fontSize={"14px"} fontWeight={"700"}>
												Referral code
											</TextCus>
										</Grid>
										<Grid item xs={12}>
											<Box
												display={"flex"}
												width={"100%"}
												gap={"15px"}
												alignItems={"center"}
												justifyContent={"space-between"}>
												<Box
													width={"400px"}
													borderRadius={"12px"}
													display={"flex"}
													padding={"0 15px"}
													gap={"15px"}
													maxWidth={"524px"}
													alignItems={"center"}
													height={"40px"}
													bgcolor={"rgba(232, 231, 254, 0.7)"}
													flex={"1"}>
													<Icons.Link />
													<TextCus
														className="res_text"
														color={"#303030"}
														fontSize={fontSize.xs}
														fontWeight={"600"}>
														{process.env.REACT_APP_URL +
															`register?_referralCode=${user?.referralCode}`}
													</TextCus>
												</Box>
												<IconButton
													onClick={() =>
														staticCopy(
															process.env.REACT_APP_URL +
																`register?_referralCode=${user?.referralCode}`,
														)
													}>
													{Icons.Copy(isCopied ? "green" : "#121219")}
												</IconButton>
											</Box>
										</Grid>
									</Grid>
								</Box>
							)}
						</Box>
						<ButtonCus
							title="Change password"
							disabled={user?.typeLogin !== TypeLogin.SYSTEM}
							onClick={onEditProfile}
						/>
					</Box>
				) : (
					<Box
						mt={"20px"}
						display={"flex"}
						gap={"20px"}
						alignItems={"center"}
						flexDirection={"column"}
						justifyContent={"center"}>
						<Box
							alignItems={"center"}
							display={"flex"}
							flexDirection={"column"}
							gap={"20px"}
							height={"100%"}
							justifyContent={"space-between"}
							padding={"20px"}
							width={"80%"}>
							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={4} fontSize={"14px"} fontWeight={"700"}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											Name
										</TextCus>
									</Grid>
									<Grid item xs={8}>
										<TextCus fontSize={fontSize.xs}>{user?.userName}</TextCus>
									</Grid>
								</Grid>
							</Box>
							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={4}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											Current plan:
										</TextCus>
									</Grid>
									<Grid item xs={8}>
										<TextCus fontSize={fontSize.xs}>{user?.plan}</TextCus>
									</Grid>
								</Grid>
							</Box>
							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={4}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											Total credits:
										</TextCus>
									</Grid>
									<Grid item xs={8}>
										<TextCus fontSize={fontSize.xs}>
											{user?.credits + ""}
										</TextCus>
									</Grid>
								</Grid>
							</Box>
							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={4}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											Total bill:
										</TextCus>
									</Grid>
									<Grid item xs={8}>
										<TextCus fontSize={fontSize.xs}>
											{"$" + user?.totalBill}
										</TextCus>
									</Grid>
								</Grid>
							</Box>
							<Box width={"100%"}>
								<Grid container>
									<Grid item xs={4}>
										<TextCus fontSize={"14px"} fontWeight={"700"}>
											Date
										</TextCus>
									</Grid>
									<Grid item xs={8}>
										<TextCus fontSize={fontSize.xs}>
											{dayjs(user?.startDate).format("HH:mm, YYYY/MM/DD")}
										</TextCus>
									</Grid>
								</Grid>
							</Box>
						</Box>
						<ButtonCus
							title="Upgrade plan"
							onClick={() => {
								onClose();
								navigate(APP_ROOT.PLAN);
							}}
						/>
					</Box>
				)}
			</Card>
		</Modal>
	);
};

export default ModalProfile;
