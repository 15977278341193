import React from "react";
import { Box, Divider, Modal } from "@mui/material";
import { ButtonCus, TextCus } from "components";
import { fontSize, mainColor } from "theme/default";
import { formatMoney } from "utils";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TDetailBill } from "types/payment";
import { useUser } from "hooks";
import { success } from "assets";

interface IModalSuccess {
	open: boolean;
	onClose: () => void;
	info: TDetailBill | null;
}
const ModalSuccess = ({ open, onClose, info }: IModalSuccess) => {
	const { user } = useUser();
	const navigate = useNavigate();
	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "rgba(0, 0, 0, 0.6)",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
				padding={"20px 10px"}
				flexDirection={"column"}
				height={"480px"}
				sx={{
					width: {
						xs: "90%",
						sm: "500px",
					},
				}}
				borderRadius={"10px"}
				bgcolor={mainColor.white}>
				<img src={success} alt="Success" />
				<TextCus fontWeight={"600"} fontSize={fontSize.xs}>
					Payment successful
				</TextCus>
				<TextCus
					fontSize={fontSize.xs}
					fontWeight={"400"}
					textAlign={"center"}
					color={"#667085"}
					maxWidth={"360px"}>
					Your payment has been successfully submitted. Please check an email.
				</TextCus>
				<Box
					padding={"20px"}
					display={"flex"}
					width={"100%"}
					borderRadius={"5px"}
					alignItems={"center"}
					justifyContent={"space-between"}>
					<TextCus
						fontSize={fontSize.sm}
						color={mainColor.textD8}
						fontWeight={"600"}>
						Total credit
					</TextCus>
					<TextCus fontSize={fontSize.md} fontWeight={"600"}>
						{(info?.totalCredit || 0) + ""}
					</TextCus>
				</Box>
				<Divider sx={{ background: "#CCCCCC", width: "100%" }} />

				<Box
					borderRadius={"5px"}
					padding={"20px"}
					width={"100%"}
					display={"flex"}
					flexDirection={"column"}
					gap={"10px"}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus
							fontSize={fontSize.xs}
							color={mainColor.textD8}
							fontWeight={"600"}>
							Invoice ID
						</TextCus>
						<TextCus fontSize={fontSize.xs}>{info?.invoiceId}</TextCus>
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus
							fontSize={fontSize.xs}
							color={mainColor.textD8}
							fontWeight={"600"}>
							Date
						</TextCus>
						<TextCus fontSize={fontSize.xs}>
							{dayjs(info?.startDate).format("DD MMM YYYY HH:mm:ss")}
						</TextCus>
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus
							fontSize={fontSize.xs}
							color={mainColor.textD8}
							fontWeight={"600"}>
							By user
						</TextCus>
						<TextCus fontSize={fontSize.xs}>{user?.userName}</TextCus>
					</Box>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus
							fontSize={fontSize.xs}
							color={mainColor.textD8}
							fontWeight={"600"}>
							Amount spent
						</TextCus>
						<TextCus fontSize={fontSize.xs}>
							{formatMoney(info?.amountSpent || 0)}
						</TextCus>
					</Box>
				</Box>
				<ButtonCus
					title="Got it, thanks"
					sx={{
						fontSize: fontSize.xs,
						fontWeight: "600",
					}}
					onClick={() => {
						onClose();
						navigate("/pricing-plan");
					}}
				/>
			</Box>
		</Modal>
	);
};

export default ModalSuccess;
