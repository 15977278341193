import React from "react";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { mainColor } from "theme/default";
import { ButtonCus, TextCus } from "components";
import { CloseOutlined } from "@mui/icons-material";
import { bgSsEvent } from "assets";
import ConfettiExplosion from "react-confetti-explosion";

interface IModalEventSuccess {
	open: boolean;
	onClose: () => void;
}

const ModalEventSuccess = ({ open, onClose }: IModalEventSuccess) => {
	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					position={"relative"}
					justifyContent={"space-between"}
					gap={"20px"}
					padding={"24px 20px"}
					flexDirection={"column"}
					sx={{
						width: {
							xs: "536px",
						},
						overflowY: "auto",
						overflowX: "hidden",
						backgroundImage: `url(${bgSsEvent})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						height: "278px",
					}}
					borderRadius={"10px"}
					bgcolor={mainColor.white}>
					<ConfettiExplosion
						duration={2500}
						particleSize={12}
						zIndex={999999}
						force={0.5}
					/>
					<IconButton
						sx={{ position: "absolute", top: 0, right: 0 }}
						onClick={onClose}>
						<CloseOutlined />
					</IconButton>
					<Box
						display={"flex"}
						flexDirection={"column"}
						gap={"5px"}
						justifyContent={"center"}
						alignItems={"center"}>
						<Box display={"flex"} alignItems={"end"} width={"100$"} gap={"4px"}>
							<Box className="text_linear" fontWeight={"600"} fontSize={"60px"}>
								+14
							</Box>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							flexDirection={"column"}
							textAlign={"center"}>
							<TextCus fontSize={"16px"} fontWeight={"700"} color={"#101828"}>
								DAYS OF FREE TRIAL
							</TextCus>
							<TextCus fontSize={"14px"} fontWeight={"400"} color={"#666666"}>
								Congratulations! You have received an additional 14 days of free
								trial
							</TextCus>
						</Box>
					</Box>
					<ButtonCus
						title="Claim it!"
						onClick={() => {
							onClose();
						}}
					/>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalEventSuccess;
