/** @format */

export const caculateVInvalid = (data, key: string) => {
	let result = 0;
	if (data?.length && key) {
		const rsTmp = data?.filter((e) => e?.result === key);
		return (result = rsTmp.length);
	}

	return result;
};
export const debounce = (func, timeout = 300) => {
	let timer;
	return function (...args) {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null;
			func.apply(debounce, args);
		}, timeout);
	};
};

export const base64Image = (baseImg: string) =>
	`data:image/png;base64, ${baseImg}`;

export function sleep(n: number) {
	return new Promise((resolve) => setTimeout(resolve, n));
}

export const renderStatusBodyShape = (bmi: number) => {
	if (bmi < 18.5) return { title: "Under weight" };
	if (bmi >= 18.5 && bmi < 25) return { title: "Normal weight" };
	if (bmi >= 25 && bmi < 30) return { title: "Over weight" };
	if (bmi > 30) return { title: "Obesity" };
};
