import { useInfiniteQuery } from "@tanstack/react-query";
import { animateService } from "services";
import { TParams } from "types";
import { QueryKeys } from "utils";

export const useAnimate = (inView?: boolean) => {
	const useGetVideo = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [
				QueryKeys.YOUR_GEN_ME,
				params.typeModel,
				params.limit,
				params.type,
			],
			queryFn: ({ pageParam = params.page || 1 }) =>
				animateService.getVideoPreset({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
			enabled: inView,
		});

		const videos = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			videos,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	return {
		useGetVideo,
	};
};
