import { END_POINT } from "utils";
import axiosInstance from "./axios";
import { TParams, TResponse, TypeServiceName } from "types";
import {
	IBodyShapeParams,
	IDataRename,
	IDetailGallery,
	IGallery,
	IModelParams,
	IModelType,
} from "types/outfit";
import axiosClient from "./axios";

export const modelService = {
	getModelServiceList: async (
		params: TParams,
		endpoint = END_POINT.MODEL.GET_EXAMPLE,
	): Promise<TResponse<IModelType[] & IDetailGallery[]>> => {
		const { page, limit, ...restParams } = params;
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();

		// Construct the full URL
		const url = `${endpoint}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data.data;
	},

	getYourModelServiceList: async (
		params: TParams,
		endpoint = END_POINT.MODEL.GET_YOUR_MODEL,
	): Promise<TResponse<IModelType[] & IGallery[]>> => {
		const { page, limit, ...restParams } = params;
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();

		// Construct the full URL
		const url = `${endpoint}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data.data;
	},

	getPresetModel: async (
		params: TParams,
		endpoint = END_POINT.MODEL.PRESET_MODEL,
	): Promise<TResponse<IModelType[]>> => {
		const { page, limit, ...restParams } = params;
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();

		// Construct the full URL
		const url = `${endpoint}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data.data;
	},

	getModelRandom: async (
		params: TParams,
		endpoint = END_POINT.MODEL.GET_RANDOM,
	): Promise<TResponse<IModelType[] & IGallery[]>> => {
		const { page, limit } = params;
		// Construct the full URL
		const url = `${endpoint}?page=${page}&limit=${limit}`;
		const data = await axiosInstance.get(url);
		return data.data;
	},
	deleteGallery: async (idGallery: string) => {
		return await postRequest(END_POINT.GALLERY.DELETE_GALLERY, {
			galleryId: idGallery,
		});
	},
	renameGallery: async (data: IDataRename) => {
		return await postRequest(END_POINT.GALLERY.RENAME_GALLERY, data);
	},
	detailGallery: async (
		idGallery: string,
		type?: TypeServiceName,
	): Promise<TResponse<IDetailGallery>> => {
		const data = await axiosInstance.get(
			END_POINT.GALLERY.DETAIL_GALLERY + idGallery + `&type=${type}`,
		);
		return data?.data;
	},
	createYourModels: async (params: IModelParams) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		return await axiosInstance.post(
			END_POINT.MODEL.CREATE_YOUR_MODEL,
			params,
			config,
		);
	},
	deleteYourModel: async (idModel: string) => {
		return await postRequest(END_POINT.MODEL.DELETE_YOUR_MODEL, {
			modelId: idModel,
		});
	},

	createYourBodyShape: async (params: IBodyShapeParams) => {
		return await postRequest(END_POINT.MODEL.CREATE_BODY_SHAPE, params);
	},
};

const postRequest = (url: string, param: any) => axiosClient.post(url, param);
