import React, { useEffect, useRef } from "react";

interface ImageToCanvasProps {
	link: string;
	isPreviewVisible?: boolean;
}

const ImageToCanvas: React.FC<ImageToCanvasProps> = ({
	link,
	isPreviewVisible,
}) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	useEffect(() => {
		if (link && canvasRef.current) {
			const canvas = canvasRef.current;
			const ctx = canvas.getContext("2d");
			if (!ctx) return;

			const img = new Image();
			img.crossOrigin = "anonymous";
			img.src = link;

			img.onload = () => {
				canvas.width = img.width;
				canvas.height = img.height;
				ctx.drawImage(img, 0, 0);
			};
			img.onerror = () => {
				alert("Failed to load image. Please check the URL.");
			};
		}
	}, [link]);

	return (
		<canvas
			ref={canvasRef}
			style={{
				width: "auto",
				height: "100%",
				borderRadius: "10px",
				opacity: isPreviewVisible ? 1 : 0,
			}}
			onContextMenu={(e) => e.preventDefault()}
		/>
	);
};

export default ImageToCanvas;
