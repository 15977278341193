import React, { useCallback, useState } from "react";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { Icons } from "assets";
import VideoPose from "./VideoPose";
import { useAnimate, useAnimateStore } from "hooks";
import { TParams } from "types";
import { TextCus } from "components";
import { RuleFolder } from "@mui/icons-material";
import { mainColor } from "theme";

const ListVideo = () => {
	const {
		selectedFileVideo,
		setSelectedFileVideo,
		setVideoAnimate,
		setCurrentStep,
	} = useAnimateStore();

	// ======================== STATE ========================
	const { useGetVideo } = useAnimate();
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 4,
	});

	const { videos, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useGetVideo(paramsModels);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<>
					<Box
						width={"100%"}
						sx={{ aspectRatio: "9 / 6 auto" }}
						display={"flex"}
						gap={"10px"}
						alignItems={"center"}
						justifyItems={"space-between"}
						flexWrap={"wrap"}>
						{[...Array(2)].map((_, index) => {
							return (
								<Skeleton
									key={index}
									variant="rectangular"
									sx={{
										flex: 1,
										backgroundColor: "#ccc",
										borderRadius: "10px",
										aspectRatio: "9 / 4 auto",
										height: "100%",
									}}
								/>
							);
						})}
					</Box>
					<Box
						width={"100%"}
						sx={{ aspectRatio: "9 / 6 auto" }}
						display={"flex"}
						gap={"10px"}
						alignItems={"center"}
						justifyItems={"space-between"}
						flexWrap={"wrap"}>
						{[...Array(2)].map((_, index) => {
							return (
								<Skeleton
									key={index + 4}
									variant="rectangular"
									sx={{
										flex: 1,
										backgroundColor: "#ccc",
										borderRadius: "10px",
										aspectRatio: "9 / 4 auto",
										height: "100%",
									}}
								/>
							);
						})}
					</Box>
				</>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"190px"}
					component={"div"}
					textAlign={"center"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
					color={"white"}>
					<RuleFolder
						style={{
							width: "60px",
							height: "60px",
							color: "#ccc",
						}}
					/>
					<TextCus color={mainColor.textGray} fontStyle={"italic"}>
						This item currently has no data.
					</TextCus>
				</Box>
			);
		}
	}, [isLoading]);

	const renderVideo = useCallback(() => {
		return (
			<>
				{videos.map((item) => (
					<VideoPose
						selectedFileVideo={selectedFileVideo as string}
						setSelectedVideo={(it) => {
							setSelectedFileVideo(it.id as string);
							setVideoAnimate(it.video as string);
							setCurrentStep(2);
						}}
						key={item.id}
						item={item}
					/>
				))}
			</>
		);
	}, [videos]);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"} height={"100%"}>
			<Box display={"flex"} gap={"10px"} rowGap={"15px"} flexWrap={"wrap"}>
				{!videos?.length || isLoading ? renderDataLoading() : renderVideo()}
			</Box>
			{hasNextPage && (
				<Box
					component={"div"}
					onClick={() => fetchNextPage()}
					width={"fit-content"}
					sx={{ cursor: "pointer" }}
					margin={"0 auto"}
					padding={"4px 10px"}
					borderRadius={"12px"}
					bgcolor={"rgba(149, 119, 217, 0.2)"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"15px"}>
					<TextCus>{"See more"}</TextCus>
					{isFetchingNextPage ? (
						<CircularProgress size={15} />
					) : (
						<Icons.ArrowSelect />
					)}
				</Box>
			)}
		</Box>
	);
};

export default ListVideo;
