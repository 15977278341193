import { Box } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React, { useCallback, useState } from "react";
import TitleSection from "./TitleSection";
import { useModel, useOutfitStore, useWindowDimensions } from "hooks";
import { TParams } from "types";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { IModelType } from "types/outfit";

const PresetModels = () => {
	const navigate = useNavigate();
	const { width } = useWindowDimensions();
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});
	const { useGetModelRandom } = useModel(inView);
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 6,
	});

	const { models: dataModel } = useGetModelRandom(paramsModels);
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();

	const handleChooseModel = useCallback((item: IModelType) => {
		setSelectedFileModel(item._id as string);
		setModelImage(item.image as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
	}, []);

	return (
		<Box ref={ref} className="wrap-content" width={"100%"} mt={"70px"}>
			<TitleSection
				title="Preset Model"
				action={() => navigate(APP_ROOT.MODELS)}
			/>
			<Box
				mt={"20px"}
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				gap={"15px"}>
				{dataModel.slice(0, width < 996 ? 5 : 6).map((item, index) => {
					return (
						<Box
							component={"div"}
							width={"133px"}
							height={"133px"}
							borderRadius={"100%"}
							border="2px solid #ccc"
							overflow={"hidden"}
							onClick={() => handleChooseModel(item)}
							display={"flex"}
							alignItems={"start"}
							justifyContent={"center"}
							sx={{
								transition: "all 0.35s ease-in-out",
								cursor: "pointer",
								":hover": {
									".img_model": {
										transform: "scale(1.075)",
									},
								},
							}}
							key={`${item?.id || "" + index}`}>
							<LazyLoadImage
								className="img_model"
								style={{
									display: "block",
									objectFit: "cover",
									width: "100%",
									transition: "all 0.35s ease-in-out",
								}}
								src={`${item?.image}`}
								srcSet={`${item?.image}`}
								alt="model"
							/>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default PresetModels;
