import { Box } from "@mui/material";
import { bnLink } from "assets";
import { ButtonCus, ModalLinkReferral, TextCus } from "components";
import React, { useState } from "react";

const BoxLink = () => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<Box
				className="wrap-content"
				width={"100%"}
				borderRadius={"12px"}
				display={"flex"}
				alignContent={"center"}
				justifyContent={"space-between"}
				alignItems={"center"}
				padding={"0 32px"}
				sx={{
					overflowY: "auto",
					overflowX: "hidden",
					backgroundImage: `url(${bnLink})`,
					backgroundPosition: "center",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					height: "72px",
				}}>
				<TextCus fontWeight={"600"} fontSize={"15px"} color={"#050358"}>
					Invite your friends to get more free 14 days of free trial
				</TextCus>
				<ButtonCus onClick={() => setOpen(true)} title="Invite now" />
			</Box>
			{open && <ModalLinkReferral open={open} onClose={() => setOpen(false)} />}
		</>
	);
};

export default BoxLink;
