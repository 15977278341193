/** @format */

import { useEffect, useState } from "react";

import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";

export const useDevice = () => {
	const platForm = "web-app";
	const [ipAddress, setIpAddress] = useState<string>("");
	const [deviceId, setDeviceId] = useState<string>("");
	const [countryCode, setCountryCode] = useState("");

	useEffect(() => {
		(async () => {
			const res = await axios.get("https://api.ipify.org/?format=json");
			setIpAddress(res.data.ip);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await navigator.geolocation.getCurrentPosition(
				(position) => {
					axios
						.get(
							`https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`,
						)
						.then((value: AxiosResponse<any>) =>
							setCountryCode(value?.data.address.country_code),
						);
				},
				(err: any) => console.log(err),
			);
		})();
	}, []);

	useEffect(() => {
		let deviceId = localStorage.getItem("deviceId");
		if (!deviceId) {
			deviceId = uuidv4();
			localStorage.setItem("deviceId", deviceId as string);
		}
		setDeviceId(deviceId as string);
	}, []);

	return { ipAddress, platForm, deviceId, countryCode };
};
