import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useGenMeStore } from "hooks";
import { TParams, TypeServiceName } from "types";
import { EDropType, IModelType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { TextCus } from "components";
import { Icons } from "assets";
import { usePresetModel } from "hooks/usePresetModel";

export const PresetModel: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		setModelImage,
		selectedFileModel,
		setSelectedFileGallery,
		setCurrentStep,
	} = useGenMeStore();
	const { useGetPresetModel } = usePresetModel();

	// ======================== STATE ========================
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 3,
		type: TypeServiceName.GEN_ME,
	});

	const {
		presetModel,
		isLoading: isLoadingFemale,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useGetPresetModel(paramsModels);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Preset Model" />
			<ListImage
				isLoading={isLoadingFemale}
				images={presetModel as IModelType[] | undefined}
				selectedImage={selectedFileModel as string}
				setSelectedImage={(it) => {
					setSelectedFileModel(it.id as string);
					setModelImage(it.image as string);
					setSelectedFileGallery(null);
					setCurrentStep(1);
				}}
				dropType={EDropType.BODY}
			/>
			{hasNextPage && (
				<Box
					component={"div"}
					onClick={() => fetchNextPage()}
					width={"fit-content"}
					sx={{ cursor: "pointer" }}
					margin={"0 auto"}
					padding={"4px 10px"}
					borderRadius={"12px"}
					bgcolor={"rgba(149, 119, 217, 0.2)"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"15px"}>
					<TextCus>See more</TextCus>
					{isFetchingNextPage ? (
						<CircularProgress size={15} />
					) : (
						<Icons.ArrowSelect />
					)}
				</Box>
			)}
		</Box>
	);
};
