import React, { memo } from "react";
import { Box } from "@mui/material";
import { kc2 } from "assets";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import { fontSize } from "theme";

const BoxCredit = () => {
	const { user } = useUser();
	return (
		<Box
			padding={"3px 10px"}
			height={"40px"}
			zIndex={99999}
			borderRadius={"12px"}
			display={"flex"}
			alignItems={"center"}
			gap={"10px"}
			bgcolor={"#FFE6664D"}>
			<img width={20} src={kc2} alt="kc" />
			<TextCus color={"#534200"} fontSize={fontSize.sm} fontWeight={"bold"}>
				{(user?.credits || 0) + ""}
			</TextCus>
		</Box>
	);
};

export default memo(BoxCredit);
