import styled from "@emotion/styled";
import { Slider } from "@mui/material";
import React from "react";

const CustomSlider = styled(Slider)({
	color: "transparent",
	marginBottom: "0px",
	marginLeft: "5px",
	"& .MuiSlider-track": {
		background: "linear-gradient(90deg, #00c6ff, #ff8ce1)",
		height: 8,
		borderRadius: 4,
	},
	"& .MuiSlider-rail": {
		backgroundColor: "#D1CFFD",
		height: 8,
		opacity: 1,
		borderRadius: 4,
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#ff00ff",
		border: "3px solid white",
		boxShadow: "unset !important",
		"&:hover": {
			boxShadow: "unset !important",
		},
		"&:active": {
			boxShadow: "unset !important",
		},
	},
	"& .MuiSlider-mark": {
		width: "8px",
		height: "8px",
		borderRadius: "100px",
		backgroundColor: "rgba(20, 4, 56, 0.15)",
		transform: "translate(-8px, -50%)",
	},
	"& .MuiSlider-markActive": {
		backgroundColor: "transparent",
	},
	"& .MuiSlider-markLabel": {
		top: "-20px",
		transform: "translateX(-65%)",
	},
});

interface ISliderRangeCus {
	setVSlider: (v: number) => void;
	valueDefault: number;
	maxValue: number;
	minValue: number;
}

const SliderRangeCus = ({
	setVSlider,
	valueDefault,
	maxValue,
	minValue,
}: ISliderRangeCus) => {
	const handleChange = (_: Event, newValue: number | number[]) => {
		if (typeof newValue === "number") {
			setVSlider(newValue);
		}
	};

	return (
		<CustomSlider
			defaultValue={0}
			value={valueDefault}
			min={minValue}
			onChange={handleChange}
			max={maxValue}
		/>
	);
};

export default SliderRangeCus;
