import { Box } from "@mui/material";
import React from "react";
import { BannerSuggest, YourBodyShape } from "./components";
import { YourModels, YourGenMe } from "pages/models/components";

const BodyShape = () => {
	return (
		<Box padding={"20px"}>
			<BannerSuggest />
			<Box height={"20px"} />
			<YourBodyShape />
			<Box height={"40px"} />
			<YourModels />
			{/* <Box height={"40px"} />
			<YourHairs /> */}
			<Box height={"40px"} />
			<YourGenMe />
		</Box>
	);
};

export default BodyShape;
