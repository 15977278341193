/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { TextCus } from "components/TextCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import { Icons } from "assets";
import { IModelType } from "types/outfit";
import { useOutfitStore, useStyleHairStore } from "hooks";
import { APP_ROOT } from "utils/routes";
import { useNavigate } from "react-router-dom";

interface IModalDetailBMI {
	open: boolean;
	onClose: () => void;
	dataResult: IModelType | null;
}

const ModalDetailBMI = ({ open, onClose, dataResult }: IModalDetailBMI) => {
	const navigate = useNavigate();
	const [previewUrl, setPreviewUrl] = useState<string | null>();
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);

	// ========== Download img ============

	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();

	const {
		setSelectedFileModel: setSelectedFileModelHair,
		setModelImage: setModelImageHair,
		setSelectedFileGallery: setSelectedFileGalleryHair,
		setCurrentStep: setCurrentStepHair,
	} = useStyleHairStore();

	useEffect(() => {
		if (dataResult?.image) {
			typeof dataResult?.image === "string" && setPreviewUrl(dataResult?.image);
		}
	}, [dataResult?.image]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleChooseModel = useCallback(() => {
		setSelectedFileModel(dataResult?.id as string);
		setModelImage(dataResult?.image as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
		onClose();
	}, [dataResult]);

	const navigateStyleHair = useCallback(() => {
		setSelectedFileModelHair(dataResult?.id as string);
		setModelImageHair(dataResult?.image as string);
		setSelectedFileGalleryHair(null);
		setCurrentStepHair(1);
		navigate(APP_ROOT.STYLE_HAIR);
		onClose();
	}, [dataResult]);

	const arrBtn = [
		{
			title: "Style Fit",
			icon: Icons.Fit(),
			action: () => handleChooseModel(),
			desc: "Change clothes",
		},
		{
			title: "Style Hair",
			icon: Icons.Hair(),
			action: () => navigateStyleHair(),
			desc: "Change hairstyle",
		},
		{
			title: "Gen Me",
			icon: Icons.Me(),
			action: () => console.log("Me"),
			desc: "Face Swap",
		},
	];

	const renderButton = useCallback(() => {
		return (
			<Box
				display={"flex"}
				alignItems={"center"}
				gap={"15px"}
				justifyContent={"space-between"}>
				{arrBtn.map((item) => (
					<Box
						onClick={item.action}
						sx={{
							cursor: "pointer",
							transition: "all 0.25s ease-in-out",
							background: mainColor.bgLinear,
							"&:hover": {
								".box_desc": {
									top: "-30px",
									opacity: 1,
								},
							},
						}}
						key={item.title}
						borderRadius={"12px"}
						flex={1}
						display={"flex"}
						alignItems={"center"}
						gap={"10px"}
						justifyContent={"center"}
						height={"44px"}
						position={"relative"}>
						{item.icon}
						<TextCus
							color={"#ffffff"}
							fontWeight={"600"}
							fontSize={fontSize.xs}>
							{item.title}
						</TextCus>
						<Box
							className="box_desc"
							position={"absolute"}
							height={"20px"}
							bgcolor={"#5553BA"}
							width={"110px"}
							borderRadius={"12px"}
							color={"white"}
							fontSize={"10px"}
							fontWeight={"400"}
							textAlign={"center"}
							lineHeight={"20px"}
							sx={{ transition: "all 0.25s ease-in-out", opacity: 0 }}
							top={"-10px"}>
							{item.desc}
						</Box>
					</Box>
				))}
			</Box>
		);
	}, [dataResult]);

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={mainColor.white}
					flexDirection={"column"}
					sx={{
						maxWidth: "500px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"600px"}
					overflow={"hidden"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						padding={"10px 20px"}
						justifyContent={"space-between"}>
						<TextCus fontWeight={"700"} fontSize={fontSize.sm}>
							Your Model
						</TextCus>
						<IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
							<CloseOutlined />
						</IconButton>
					</Box>
					<Box
						padding={"20px"}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"space-between"}
						gap={"20px"}
						width={"100%"}
						height={"540px"}>
						<Box
							display={"flex"}
							alignItems={"flex-start"}
							height={"calc(100% - 120px)"}
							width={"100%"}
							gap={"15px"}
							justifyContent={"center"}>
							<Box position={"relative"} borderRadius={"12px"} height={"100%"}>
								<img
									src={previewUrl + ""}
									alt="Preview"
									style={{
										display: "block",
										height: "100%",
										maxWidth: "100%",
										opacity: isPreviewVisible ? 1 : 0,
										transition: "opacity 0.5s ease-in-out",
										borderRadius: "10px",
										objectFit: "cover",
									}}
								/>
							</Box>
						</Box>

						<Box
							display={"flex"}
							gap={"10px"}
							flexDirection={"column"}
							justifyContent={"space-between"}
							borderRadius={"12px"}
							width={"100%"}
							padding={"15px"}
							border={"1px solid #EEEEEE"}>
							<TextCus
								color={mainColor.textMain}
								fontSize={fontSize.xs}
								fontWeight={"600"}>
								Create with:
							</TextCus>
							{renderButton()}
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalDetailBMI;
