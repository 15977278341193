import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { ButtonCus, ModalRequirements, TextCus } from "components";
import { Icons, up } from "assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fontSize, mainColor } from "theme";
import {
	arrNotShould,
	arrNotShouldItem,
	arrShould,
	arrShouldItem,
	KEY_CONTEXT,
} from "utils";
import { useOutfitStore } from "hooks";
import { useLocation } from "react-router-dom";

interface BoxUploadProps {
	onFileSelect?: (file: File) => void;
	onClear?: () => void;
	initialImage?: string;
}

const BoxUpload = ({ initialImage, onFileSelect }: BoxUploadProps) => {
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const { setCurrentStep, currentStep } = useOutfitStore();
	const location = useLocation();

	useEffect(() => {
		if (initialImage) {
			typeof initialImage === "string" && setPreviewUrl(initialImage);
		}
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setOpenModal(false);
			onFileSelect?.(file);
			const url = URL.createObjectURL(file);
			setPreviewUrl(url);
			currentStep < 2 && setCurrentStep(currentStep + 1);

			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};
	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<Box
			className="dash_linear"
			width={"100%"}
			height={"100%"}
			display={"flex"}
			flexDirection={"column"}
			gap={"25px"}
			justifyContent={"center"}
			alignItems={"center"}
			border={"2px dashed #fff"}>
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: "none" }}
				accept=".png, .jpg, .jpeg, .webp"
				onChange={handleFileChange}
			/>
			{previewUrl ? (
				<img
					src={previewUrl}
					alt="Preview"
					style={{
						maxWidth: "90%",
						maxHeight: "70%",
						opacity: isPreviewVisible ? 1 : 0,
						transition: "opacity 0.5s ease-in-out",
						borderRadius: "10px",
						margin: "0 auto",
					}}
				/>
			) : (
				<>
					<LazyLoadImage src={up} />
					<TextCus
						fontSize={fontSize.xs}
						fontWeight={"400"}
						textAlign={"center"}
						color={mainColor.textGray}>
						Drop or upload your photo here
					</TextCus>
				</>
			)}
			<ButtonCus
				sx={{ gap: "15px" }}
				onClick={() => {
					localStorage.getItem(
						KEY_CONTEXT.MODAL_REQUIRE + currentStep + location.pathname,
					)
						? handleClick()
						: setOpenModal(true);
				}}>
				{Icons.Upload()}
				<TextCus fontSize={fontSize.xs} fontWeight={"600"}>
					Upload
				</TextCus>
			</ButtonCus>
			<ModalRequirements
				currentStep={currentStep}
				open={openModal}
				onClose={() => setOpenModal(false)}
				handleClick={handleClick}
				arrContentShould={currentStep === 0 ? arrShould : arrShouldItem}
				arrContentNotShould={
					currentStep === 0 ? arrNotShould : arrNotShouldItem
				}
			/>
		</Box>
	);
};

export default BoxUpload;
