import React, { useCallback, useRef, useState } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { fontSize, mainColor } from "theme";
import { DialogVideo, TextCus } from "components";
import { Icons } from "assets";
import { TVideoAnimate } from "types/animate";

interface IVideoPose {
	setSelectedVideo?: (video: TVideoAnimate) => void;
	item: TVideoAnimate;
	selectedFileVideo: string;
}

const VideoPose = ({
	setSelectedVideo,
	item,
	selectedFileVideo,
}: IVideoPose) => {
	const refVideo = useRef<HTMLVideoElement>();
	const [isPlaying, setIsPlaying] = useState(false);
	const handleMouseEnter = () => {
		if (refVideo.current && !isPlaying) {
			setIsPlaying(true);
			refVideo.current.play().catch((error) => {
				console.error("Error playing video:", error);
			});
		}
	};
	const handleMouseLeave = () => {
		if (refVideo.current && isPlaying) {
			setIsPlaying(false);
			refVideo.current.pause();
		}
	};

	const [isLoading, setIsLoading] = useState(true);
	const handleLoadStart = useCallback(() => {
		setIsLoading(true);
	}, []);
	const handleCanPlay = useCallback(() => {
		setIsLoading(false);
	}, []);
	const handlePlaying = useCallback(() => {
		setIsLoading(false);
	}, []);
	const handleWaiting = useCallback(() => {
		setIsLoading(true);
	}, []);

	const [open, setOpen] = useState(false);
	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);
	const handleOpenVideo = useCallback(() => {
		setOpen(true);
		if (refVideo.current) refVideo.current.pause();
		setIsPlaying(false);
	}, []);
	return (
		<Box
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={() => setSelectedVideo?.(item)}
			display={"flex"}
			flexDirection={"column"}
			sx={{
				cursor: "pointer",
				aspectRatio: "151 / 201",
				transition: "all 0.25s ease-in-out",
				borderRadius: "12px",
				":hover": {
					".item_video_pose": {
						opacity: 1,
					},
				},
			}}
			width={"calc((100% - 10px) / 2)"}
			gap={"10px"}
			alignItems={"center"}>
			<Box
				position={"relative"}
				height={"100%"}
				width={"100%"}
				borderRadius={"12px"}
				sx={{
					background:
						selectedFileVideo === item?.id ? mainColor.bgLinear : "transparent",
					padding: "3px",
				}}
				overflow={"hidden"}>
				<Box
					ref={refVideo}
					borderRadius={"12px"}
					sx={{ objectFit: "cover" }}
					width={"100%"}
					height={"100%"}
					component={"video"}
					autoPlay={false}
					controls={false}
					onLoadStart={handleLoadStart}
					onCanPlay={handleCanPlay}
					onPlaying={handlePlaying}
					onWaiting={handleWaiting}
					loop
					muted>
					<source src={item.video} type="video/mp4" />
				</Box>
				{isLoading && (
					<Box
						bgcolor={"rgba(0, 0, 0, 0.7)"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						position={"absolute"}
						top={"50%"}
						left={"50%"}
						sx={{ transform: "translate(-50%,-50%)" }}
						width={"100%"}
						height={"100%"}>
						<CircularProgress size={25} />
					</Box>
				)}
				<Box
					position={"absolute"}
					bottom={0}
					className="item_video_pose"
					left={0}
					width={"100%"}
					height={"40px"}
					component={"div"}
					display={"flex"}
					zIndex={9}
					alignItems={"center"}
					justifyContent={"flex-end"}
					sx={{
						opacity: 0,
						transition: "all 0.25s ease-in-out",
						background:
							"linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))",
					}}>
					<IconButton onClick={handleOpenVideo}>
						<Icons.FullScreen />
					</IconButton>
				</Box>
			</Box>
			<TextCus
				fontSize={fontSize.xs}
				className="res_text"
				maxWidth="100%"
				fontWeight={"400"}
				color={"#5B3E9B"}>
				{item?.name}
			</TextCus>
			{open && (
				<DialogVideo
					urlVideo={item?.video}
					title={item?.name}
					date={item?.createdAt}
					open={open}
					handleClose={handleClose}
				/>
			)}
		</Box>
	);
};

export default VideoPose;
