import { Box } from "@mui/material";
import { bgBn1, bgTrial, king, leftBn1 } from "assets";
import { TextCus } from "components";
import { useLayout, useUser } from "hooks";
import React, { memo } from "react";
import { EModelAuth } from "types";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BannerSuggest = () => {
	const { setOpenModalAuth } = useLayout();
	const { languages } = useUser();

	return (
		<Box
			bgcolor={"#A3A3A3"}
			borderRadius={"21px"}
			width={"100%"}
			sx={{
				overflowY: "auto",
				overflowX: "hidden",
				backgroundImage: `url(${bgBn1})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "264px",
			}}>
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				width={"90%"}
				height={"100%"}
				margin={"auto"}>
				<Box
					display={"flex"}
					padding={"30px"}
					justifyContent={"space-center"}
					gap={"40px"}
					sx={{
						marginLeft: {
							xs: "0px",
							xl: "40px",
						},
					}}
					flexDirection={"column"}>
					<Box
						display={"flex"}
						flexDirection={"column"}
						width={"100%"}
						gap={"10px"}>
						<TextCus
							sx={{
								fontSize: {
									xs: "20px",
									xl: "22px",
								},
							}}
							fontWeight={"700"}
							color={"#ffffff"}>
							Subscribe now and get
						</TextCus>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "start",
								position: "relative",
								backgroundImage: `url(${bgTrial})`,
								backgroundPosition: "center",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								height: "36px",
								transition: "all 0.10s ease-in-out",
							}}
							width={languages.value === "en" ? "300px" : "345px"}
							paddingX={"10px"}
							borderRadius={"8px"}>
							<TextCus fontSize={"18px"} fontWeight={"700"} color={"#ffffff"}>
								+ 14 DAYS OF FREE TRIAL
							</TextCus>
							<Box position={"absolute"} right={"-5px"} top={"-20px"}>
								<img src={king} alt="king" />
							</Box>
						</Box>
					</Box>
					<Box
						component={"div"}
						onClick={() => setOpenModalAuth(EModelAuth.REGISTER)}
						bgcolor={"#FFFFFF"}
						mt={"10px"}
						sx={{
							transition: "all 0.25s ease-in-out",
							cursor: "pointer",
							"&:hover": {
								opacity: 0.8,
							},
						}}
						width={"173px"}
						height={"41px"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						borderRadius={"12px"}>
						<TextCus color={"#050358"} fontWeight={"600"} fontSize={"15px"}>
							Subscribe now
						</TextCus>
					</Box>
				</Box>
				<LazyLoadImage
					style={{ marginRight: "50px" }}
					src={leftBn1}
					alt="Left"
				/>
			</Box>
		</Box>
	);
};

export default memo(BannerSuggest);
