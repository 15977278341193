/** @format */

import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Banner, BoxPlan } from "./components";
import { ButtonToggleCus } from "components";
import { ResponsePlan } from "types/payment";
import { DATA_BUTTON, QueryKeys } from "utils";
import { paymentService } from "services";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "hooks";
import { sale } from "assets";
import { useSearchParams } from "react-router-dom";

const Plan = () => {
	const [typePlan, setTypePlan] = useState(DATA_BUTTON[0]);
	const { data, isLoading } = useQuery({
		queryKey: [QueryKeys.GET_PLAN, typePlan.value],
		queryFn: () => paymentService.listPlan(typePlan.value),
	});
	const { user } = useUser();
	const [showModel, setShowModel] = useState(false);

	const [searchParams] = useSearchParams();
	const param = searchParams.get("param");

	useEffect(() => {
		if (param === "custom-pricing") {
			setShowModel(true);
		}
	}, [param]);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			paddingBottom={"100px"}
			sx={{
				gap: {
					xs: "50px",
					xl: "100px",
				},
			}}>
			<Banner />

			<Box
				display={"flex"}
				alignItems={"center"}
				gap={"2px"}
				justifyContent={"center"}>
				<ButtonToggleCus typePlan={typePlan} setTypePlan={setTypePlan} />
				<Box pt={"20px"} ml={"2px"}>
					<img src={sale} alt="sale" />
				</Box>
			</Box>
			{isLoading ? (
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					height={"100px"}>
					<CircularProgress />
				</Box>
			) : (
				<Box
					marginTop={"30px"}
					justifyContent={"center"}
					maxWidth={"90%"}
					display={"flex"}
					alignItems={"start"}
					justifyItems={"center"}
					flexWrap={"wrap"}
					gap={"20px"}
					margin={"auto"}>
					{data?.data?.map((v: ResponsePlan) => {
						const isFree = v.packageName === "FREE";
						const isEnterprise = v.packageName === "ENTERPRISE";
						const isCurrentPlan = user?.totalBill === v.priceSale;
						const isDowngrade = (user?.totalBill || 0) > v.priceSale;
						return (
							<BoxPlan
								isFree={isFree}
								item={v}
								key={v?._id}
								typePlan={typePlan}
								isEnterprise={isEnterprise}
								isCurrentPlan={isCurrentPlan}
								isDowngrade={isDowngrade}
								showModel={showModel}
								setShowModel={setShowModel}
							/>
						);
					})}
				</Box>
			)}
		</Box>
	);
};

export default Plan;
