import { useMutation } from "@tanstack/react-query";
import { aiService } from "services";
import { IAnimateParams } from "types/animate";
import { IGenMeParams } from "types/genMe";
import { IOutfitParams } from "types/outfit";
import { IStyleHairParams } from "types/styleHair";

export const useAITools = () => {
	const { mutate: generateOutfit } = useMutation({
		mutationFn: (form: IOutfitParams) => aiService.outfitTools(form),
	});

	const { mutate: generateStyleHair } = useMutation({
		mutationFn: (form: IStyleHairParams) => aiService.styleHairTools(form),
	});

	const { mutate: generateGenMe } = useMutation({
		mutationFn: (form: IGenMeParams) => aiService.faceSwapTools(form),
	});

	const { mutate: generateAnimate } = useMutation({
		mutationFn: (form: IAnimateParams) => aiService.animateTools(form),
	});

	return {
		generateOutfit,
		generateStyleHair,
		generateGenMe,
		generateAnimate,
	};
};
