import React from "react";
import { Box, Fade, Modal } from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { ButtonCus, TextCus } from "components";
import { maintain } from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { useTranslation } from "react-i18next";

interface IModalNotiMaintain {
	open: boolean;
	onClose: () => void;
}

const ModalNotiMaintain = ({ open, onClose }: IModalNotiMaintain) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();

	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "rgba(0, 0, 0, 0.6)",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					position={"relative"}
					justifyContent={"center"}
					gap={"20px"}
					padding={"20px 20px"}
					flexDirection={"column"}
					height={"400px"}
					sx={{
						width: {
							xs: "620px",
						},
					}}
					borderRadius={"10px"}
					bgcolor={mainColor.white}>
					<Box className="text_linear" fontSize={"20px"} fontWeight={"700"}>
						{t("We’re sorry for the interruption!")}
					</Box>
					<img width={140} height={140} src={maintain} alt="Warning" />
					<TextCus
						fontSize={"14px"}
						maxWidth={"500px"}
						color={"#666666"}
						textAlign={"center"}>
						Our app is under maintenance to improve your experience. If you
						continue, some results may not display correctly.
					</TextCus>

					{location.pathname === APP_ROOT.STYLE_FIT ||
					location.pathname === APP_ROOT.STYLE_HAIR ||
					location.pathname === APP_ROOT.GEN_ME ||
					location.pathname === APP_ROOT.ANIMATE ? (
						<Box
							display={"flex"}
							padding={"0 20px"}
							width={"100%"}
							alignItems={"center"}
							gap={"20px"}
							justifyContent={"center"}>
							<ButtonCus
								title="Continue"
								sx={{ flex: 1, mt: "10px" }}
								onClick={() => {
									onClose();
								}}
							/>
							<ButtonCus
								sx={{ flex: 1, mt: "10px", padding: "2px !important" }}
								onClick={() => {
									onClose();
									navigate(APP_ROOT.MAINTAIN);
								}}>
								<Box
									bgcolor={"white"}
									width={"100%"}
									borderRadius={"10px"}
									height={"100%"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}>
									<Box
										display={"flex"}
										alignItems={"center"}
										gap={"15px"}
										className="text_linear"
										fontSize={fontSize.xs}
										fontWeight={"600"}>
										{t("Back to Maintain")}
									</Box>
								</Box>
							</ButtonCus>
						</Box>
					) : (
						<ButtonCus
							title="Back to Maintain"
							sx={{ mt: "10px" }}
							onClick={() => {
								onClose();
							}}
						/>
					)}
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalNotiMaintain;
