import { useCallback, useEffect, useState } from "react";

export const useCopyClipboard = (timeout = 500) => {
	const [isCopied, setIsCopied] = useState(false);

	const staticCopy = useCallback((text: any) => {
		navigator.clipboard.writeText(text);
		setIsCopied(true);
	}, []);

	useEffect(() => {
		if (isCopied) {
			const hide = setTimeout(() => {
				setIsCopied(false);
			}, timeout);

			return () => {
				clearTimeout(hide);
			};
		}
		return undefined;
	}, [isCopied, setIsCopied, timeout]);

	return { isCopied, staticCopy };
};
