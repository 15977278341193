import { TParams, TResponse } from "types";
import axiosInstance from "./axios";
import { END_POINT } from "utils";
import { IModelParams } from "types/outfit";

export const genMeService = {
	getYourGenMe: async (
		params: TParams,
		endpoint = END_POINT.GEN_ME.GET_YOUR_GEN_ME,
	): Promise<TResponse<any[]>> => {
		const { page, limit } = params;
		const url = `${endpoint}?page=${page}&limit=${limit}`;
		const data = await axiosInstance.get(url);
		return data.data;
	},
	createYourGenMe: async (params: IModelParams) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		return await axiosInstance.post(
			END_POINT.GEN_ME.CREATE_YOUR_GEN_ME,
			params,
			config,
		);
	},
	deleteYourGenMe: async (genMeId: string) => {
		return await axiosInstance.post(END_POINT.GEN_ME.DELETE_YOUR_GEN_ME, {
			genMeId,
		});
	},
};
