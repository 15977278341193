/** @format */

import { Box } from "@mui/material";
import { banner } from "assets";
import { TextCus } from "components";
import React, { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { mainColor } from "theme";

const Banner = ({ title }: { title: string }) => {
	return (
		<Box
			width={"100%"}
			position={"relative"}
			display={"flex"}
			borderRadius={"24px"}>
			<LazyLoadImage
				style={{ display: "block", objectFit: "contain", width: "100%" }}
				src={banner}
				alt="banner"
			/>
			<Box
				display={"flex"}
				position={"absolute"}
				width={"100%"}
				height={"100%"}
				left={"0"}
				justifyContent={"center"}
				alignItems={"center"}
				gap={"20px"}
				flexDirection={"column"}>
				<TextCus
					sx={{
						fontSize: {
							xs: "30px",
							md: "60px",
						},
					}}
					fontWeight={"700"}
					color={mainColor.primary}>
					{title}
				</TextCus>
			</Box>
		</Box>
	);
};

export default memo(Banner, (prev, next) => prev.title === next.title);
