import React, { memo, useCallback, useRef, useState } from "react";
import i18next from "i18next";
import { Box } from "@mui/material";
import { useUser } from "hooks";
import { TextCus } from "components/TextCus";
import { Icons } from "assets";
import useClickOutside from "hooks/useClickOutside";
import { LANGUAGES } from "utils";

const MultiLanguage = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { setLanguages, languages } = useUser();
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));

	const changeLanguage = useCallback((item) => {
		i18next.changeLanguage("en");
		setLanguages(item);
		setIsOpen(false);
	}, []);

	const renderLanguages = useCallback(() => {
		return LANGUAGES.map((language) => {
			const isActive = languages.value === language.value;
			return (
				<Box
					height={"44px"}
					width={"210px"}
					padding={"15px"}
					key={language.name}
					bgcolor={isActive ? "rgba(149, 119, 217, 0.2)" : "transparent"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					borderRadius={"12px"}
					sx={{ cursor: "pointer" }}
					onClick={() => changeLanguage(language)}>
					<Box display={"flex"} alignItems={"center"} gap={"10px"}>
						<img src={language.img} alt={language.name} />
						<TextCus color={"black"} fontSize={"13px"} fontWeight={"400"}>
							{language.name}
						</TextCus>
					</Box>
					{isActive && <Icons.Check />}
				</Box>
			);
		});
	}, [languages.value]);

	return (
		<Box
			ref={dropdownRef}
			mb={"20px"}
			bgcolor={isOpen ? "rgba(149, 119, 217, 0.2)" : "transparent"}
			paddingX={"15px"}
			height={"44px"}
			borderRadius={"12px"}
			display={"flex"}
			sx={{ cursor: "pointer", transition: "all 0.25s ease-in-out" }}
			alignItems={"center"}
			justifyContent={"space-between"}
			position={"relative"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
				width={"100%"}
				height={"100%"}
				onClick={() => setIsOpen(true)}>
				<Box display={"flex"} alignItems={"center"} gap={"10px"}>
					<img src={languages.img} alt={languages.name} />
					<TextCus color={"black"} fontSize={"14px"} fontWeight={"400"}>
						{languages.name}
					</TextCus>
				</Box>
				<Icons.NextRight />
			</Box>

			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				position={"absolute"}
				zIndex={999}
				bgcolor={"white"}
				padding={"10px"}
				borderRadius={"12px"}
				boxShadow={"0 0 20px 0 rgba(115, 64, 216, 0.3)"}
				top={isOpen ? "0" : "20px"}
				right={"-240px"}
				sx={{
					opacity: isOpen ? 1 : 0,
					transition: "all 0.25s ease-in-out",
				}}
				justifyContent={"center"}>
				{renderLanguages()}
			</Box>
		</Box>
	);
};

export default memo(MultiLanguage);
