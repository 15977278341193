/** @format */

import React from "react";
import { mainColor } from "theme";
import { Box, Grid } from "@mui/material";
import { Header, Sidebar } from "pages/admin/animate/components";
import { useAnimateStore } from "hooks";

interface ProcessAnimateLayoutProps {
	children: React.ReactNode;
}

export const ProcessAnimateLayout: React.FC<ProcessAnimateLayoutProps> = ({
	children,
}) => {
	const { isLoadingProcess, currentStep } = useAnimateStore();
	return (
		<Box bgcolor={mainColor.primary} minHeight={"100vh"}>
			<Header />
			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				alignItems={"center"}
				position={"relative"}
				justifyContent={"center"}>
				{/* Show when generate */}
				<Box
					bgcolor={"rgba(0, 0, 0, 0.5)"}
					width={"100%"}
					height={"100%"}
					position={"absolute"}
					sx={{
						opacity: isLoadingProcess ? 1 : 0,
						backdropFilter: "blur(4px)",
						pointerEvents: isLoadingProcess ? "unset" : "none",
						transition: "all 0.25s ease-in-out",
					}}
					top={0}
					left={0}
					right={0}
					zIndex={9999}
				/>
				{/* End show when generate */}
				<Box
					width={"100%"}
					height={"100%"}
					minHeight="calc(100vh - 76px)"
					component={"main"}>
					<Grid container height={"calc(100vh - 68px)"} overflow={"hidden"}>
						{/* ============== SIDEBAR ================ */}
						<Grid
							item
							xs={4}
							md={3}
							xl={2}
							borderRight={`1px solid ${mainColor.borderColor}`}
							bgcolor={mainColor.white}
							display={currentStep === 3 ? "none" : "block"}
							sx={{
								flex: 1,
							}}>
							<Sidebar />
						</Grid>
						{/* ============== END SIDEBAR ================ */}

						{/* ============== MAIN CONTENT ================ */}
						<Grid
							item
							xs={currentStep === 3 ? 10 : 8}
							md={9}
							xl={10}
							height={"100%"}
							sx={{
								margin: "0 auto",
								overflowY: "auto",
								overflowX: "hidden",
							}}>
							{children}
						</Grid>
						{/* ============== END MAIN CONTENT ================ */}
					</Grid>
				</Box>
			</Box>
		</Box>
	);
};
