import { Box } from "@mui/material";
import { notFound } from "assets";
import { TextCus } from "components";
import { Header } from "pages/admin/dashboard/components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"space-between"}
			sx={{
				overflowY: "auto",
				overflowX: "hidden",
				backgroundImage: `url(${notFound})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100vh",
			}}>
			<Box width={"100%"}>
				<Header />
			</Box>
			<Box
				flex={1}
				gap={"20px"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}>
				<TextCus fontSize={"150px"} color={"#FFFFFF"} fontWeight={"700"}>
					404
				</TextCus>
				<TextCus fontSize={"34px"} color={"#FFFFFF"} fontWeight={"700"}>
					This page could not be found
				</TextCus>
				<TextCus fontSize={"16px"} color={"#FFFFFF"} fontWeight={"400"}>
					Sorry, we couldn’t fine what you search for. Please try searching
					again.
				</TextCus>
				<Box
					bgcolor={"white"}
					width={"188px"}
					height={"41px"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					color={"#3B3899"}
					borderRadius={"12px"}
					sx={{
						cursor: "pointer",
						transition: "all 0.25s ease-in-out",
						":hover": {
							opacity: 0.7,
						},
					}}
					fontSize={"14px"}
					component={"button"}
					border={"none"}
					onClick={() => navigate(APP_ROOT.DASHBOARD)}
					fontWeight={"600"}>
					Back to home
				</Box>
			</Box>
		</Box>
	);
};

export default NotFound;
