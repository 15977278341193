/** @format */
import React, { useCallback, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { StepperCus } from "components";
import { EDropType } from "types/outfit";
import { useGenMeStore, useOutfitStore, useStyleHairStore } from "hooks";
import { BoxPhoto, BoxResult } from "../animate/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "utils";
import { modelService } from "services";
import { APP_ROOT } from "utils/routes";
import { TypeServiceName } from "types";
import { animate } from "assets";

const HistoryAnimate = () => {
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const navigate = useNavigate();
	const { data, isLoading } = useQuery({
		queryKey: [QueryKeys.GET_DETAIL_GALLERY, id],
		queryFn: () => modelService.detailGallery(id + "", TypeServiceName.ANIMATE),
		enabled: !!id,
	});
	const {
		setCurrentStep,
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setName,
	} = useGenMeStore();

	const handleSwitchItem = useCallback(() => {
		setModelImage(data?.data?.image as string);
		setSelectedFileGallery(data?.data?.id as string);
		setCurrentStep(0);
		setSelectedFileModel(null);
		navigate(APP_ROOT.GEN_ME);
	}, [data]);

	const {
		setCurrentStep: setCurrentStepFit,
		setSelectedFileModel: setSelectedFileModelFit,
		setModelImage: setModelImageFit,
		setSelectedFileGallery: setSelectedFileGalleryFit,
	} = useOutfitStore();

	const handleNavigateStyleFit = useCallback(() => {
		setCurrentStepFit(0);
		setSelectedFileModelFit(null);
		setModelImageFit(data?.data?.image as string);
		setSelectedFileGalleryFit(data?.data?.id as string);
		navigate(APP_ROOT.STYLE_FIT);
	}, [data]);

	const {
		setCurrentStep: setCurrentStepHair,
		setSelectedFileModel: setSelectedFileModelHair,
		setModelImage: setModelImageHair,
		setSelectedFileGallery: setSelectedFileGalleryHair,
	} = useStyleHairStore();

	const handleNavigateStyleHair = useCallback(() => {
		setCurrentStepHair(0);
		setSelectedFileModelHair(null);
		setModelImageHair(data?.data?.image as string);
		setSelectedFileGalleryHair(data?.data?.id as string);
		navigate(APP_ROOT.STYLE_HAIR);
	}, [data]);

	useEffect(() => {
		if (data?.data) setName(data.data.name);
	}, [data?.data?.name]);

	return (
		<Box height={"100%"}>
			<Box
				height={"100px"}
				sx={{
					height: {
						xs: "120px",
						xl: "150px",
					},
				}}
				width={"100%"}
				display={"flex"}
				alignItems={"center"}>
				<StepperCus
					currentStep={5}
					steps={[
						"Upload Primary Photo",
						"Upload Desired Face Photo",
						"Generate",
						"See Your Result",
					]}
				/>
			</Box>
			<Box
				width={"90%"}
				margin={"auto"}
				sx={{
					height: { xs: "calc(100vh - 198px)", xl: "calc(100vh - 228px)" },
				}}>
				<Grid
					container
					sx={{
						maxWidth: {
							xs: "823px",
							xl: "1200px",
						},
						margin: "auto",
					}}
					spacing={2}
					height={"100%"}>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							padding: {
								xs: "0px !important",
								md: "16px 0 0 16px !important",
							},
							height: {
								xs: "170px",
								md: "100%",
							},
						}}>
						<Box
							display={"flex"}
							gap={"20px"}
							sx={{
								flexDirection: { xs: "row", md: "column" },
								height: {
									xs: "unset",
									md: "95%",
								},
							}}
							justifyContent={"flex-start"}
							alignItems={"center"}>
							<BoxPhoto
								dropType={EDropType.BODY}
								title="Primary Photo"
								description="Human photo will be shown here"
								initialImage={data?.data.modelImage as string}
								active={true}
								currentStep={1}
							/>
							<BoxPhoto
								dropType={EDropType.OUTFIT}
								title="Replacement Photo"
								description="Chosen pose will be shown here"
								active={true}
								initialImage={data?.data.videoAnimation as string}
								currentStep={2}
								imgDf={animate}
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={8}
						sx={{
							height: {
								xs: "calc(100% - 180px)",
								md: "100%",
							},
							padding: {
								xs: "0px !important",
								md: "16px 0 0 16px !important",
							},
						}}>
						<BoxResult
							initialImage={data?.data.video as string}
							valueProcess={0}
							isLoading={isLoading}
							isLoadingHistory
							isStart={false}
							handleSwitchItem={handleSwitchItem}
							processing={""}
							handleNavigateStyleFit={handleNavigateStyleFit}
							handleNavigateStyleHair={handleNavigateStyleHair}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default HistoryAnimate;
